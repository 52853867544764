export const common = {
  flexCol: {
    display: "flex",
    flexDirection: "column",
  },
};
/*
export const notSoCommonButStillShared = {
  colors: {
    light: "#ffff",
    dark: "#0000",
  },
  // Function that might create a dynamic style based on args...
  fun(a, b, c) {
    return {
      a: {
        border: "",
        color: "#ffffff",
        ...a,
      },
      b: {
        display: "inline",
        ...b,
      },
    };
  },
};

export const otherEtc = {
  colors: {
    light: "#ffff",
    dark: "#0000",
  },
  fun(a, b, c) {
    return {
      a: {
        border: "",
        color: "#ffffff",
        ...a,
      },
      b: {
        display: "inline",
        ...b,
      },
    };
  },
};
*/
