import { createPages } from "../lib";

import { MainPages } from "./main";
import { OfferPages } from "./offer";
import { SellPages } from "./sell";

export const Pages = createPages({
  offer: OfferPages,
  sell: SellPages,
  main: MainPages,
});
export default Pages;
