import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  // AxiosResponse,
  // AxiosError,
} from "axios";
import qs from "query-string";
// Local
import { REACT_APP_API_URL } from "../../config";

/** Connection used to make authorized, authenticated API calls. */
let apiClient: AxiosInstance;

/** Client for making authenticated API calls. */
export const authClient = {
  delete(url: string, config?: AxiosRequestConfig) {
    return apiClient.delete(url, config);
  },
  download(url: string, config?: AxiosRequestConfig) {
    return Promise.reject("TODO: Implement authClient.download.");
  },
  get<T = any>(url: string, config?: AxiosRequestConfig) {
    return apiClient.get<T>(url, config);
  },
  post<T = any>(url: string, data: any, config?: AxiosRequestConfig) {
    return apiClient.post<T>(url, data, config);
  },
  put<T = any>(url: string, data: any, config?: AxiosRequestConfig) {
    return apiClient.put<T>(url, data, config);
  },
};

/**
 * Serializes URL params correctly for `express-openapi-validator`. See:
 * - https://github.com/axios/axios/issues/678#issuecomment-634632500
 * - https://github.com/axios/axios/blob/8a8c534a609cefb10824dec2f6a4b3ca1aa99171/lib/helpers/buildURL.js
 * - https://github.com/axios/axios/blob/59ab559386273a185be18857a12ab0305b753e50/lib/utils.js#L177
 *
 * @param params The query params.
 */
function serializeParams(params: Record<string, any>) {
  if (params instanceof URLSearchParams) {
    return params.toString();
  }
  const formattedParams = {};
  const keys = Object.keys(params);
  const { length } = keys;
  for (let i = 0; i < length; i++) {
    const key = keys[i];
    let value = params[key];
    if (value === null || value === undefined) {
      continue;
    }
    if (Object.prototype.toString.call(value) === "[object Date]") {
      // Format Dates...
      value = value.toISOString();
    } else if (value !== null && typeof value === "object") {
      // Format objects and arrays...
      value = JSON.stringify(value);
    }
    formattedParams[key] = value;
  }
  // URLSearchParams does not handle arrays...
  // return new URLSearchParams(formattedParams).toString();
  return qs.stringify(formattedParams);
}
axios.defaults.paramsSerializer = serializeParams;

(function createApiClient() {
  const config: AxiosRequestConfig = {
    baseURL: REACT_APP_API_URL,
    headers: {
      "Content-Type": "application/json",
    },
  };
  // if (state && state.token) {
  //   config.headers.Authorization = `Bearer ${state.token}`;
  // }
  apiClient = axios.create(config);
})();
