import React from "react";

/** @type {React.Context<SellWizardContextType>} */
export const SellWizardContext = React.createContext(undefined);

export const SellWizardContextProvider = React.memo(
  /**
   * @param {SellWizardContextType} param0
   */
  function SellWizardContextProvider({ children, ...context }) {
    return (
      <SellWizardContext.Provider value={context}>
        {children}
      </SellWizardContext.Provider>
    );
  },
);
