import React from "react";
import {
  Backdrop,
  CircularProgress,
  // Container,
  IconButton,
  Snackbar,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
// Icons
import CloseIcon from "@material-ui/icons/Close";
// Local
import { uiSelectors, uiActions, useAction, useSelector } from "../../state";
// import { MainToolbar } from "./MainToolbar";
import { useStyles } from "./MainLayout.styles";

const notificationAnchor = { vertical: "top", horizontal: "center" };

// CONSIDER: Use <Alert /> inside of <Snackbar> to display different types of
// notifications. See https://material-ui.com/components/snackbars/#customized-snackbars

export * from "./MainToolbar";

export const MainLayout = React.memo(
  /**
   *
   */
  function MainLayout({ children }) {
    const classes = useStyles();
    const loading = useSelector(uiSelectors.loading);
    const notification = useSelector(uiSelectors.notification);
    const hideNotification = useAction(uiActions.hideNotification);
    return (
      <>
        {/* <Container className={classes.root} maxWidth="xl" disableGutters={true}>
          <MainToolbar />
          <div className={classes.content}>{children}</div>
        </Container> */}
        {children}
        <Snackbar
          anchorOrigin={notificationAnchor}
          open={!!notification}
          onClose={hideNotification}
          autoHideDuration={notification?.duration}
          message={notification?.message}
        >
          {notification?.variant && (
            <Alert
              severity={notification.variant}
              action={
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={hideNotification}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              }
            >
              {notification.message}
            </Alert>
          )}
        </Snackbar>
        {loading && (
          <Backdrop className={classes.backdrop} open={true}>
            <CircularProgress size={128} />
          </Backdrop>
        )}
      </>
    );
  },
);
