// import { createSelector } from "@reduxjs/toolkit";
import { AppState } from "../types";

export const sellSelectors = {
  all(state: AppState) {
    return state.sell;
  },
  dealer(state: AppState) {
    return state.sell.dealer;
  },
  toGetVinWizardState(vin: string) {
    return (state: AppState) => state.sell;
  },
};
