import React from "react";
import {
  //
  Box,
  // Grid,
  // Paper,
  // Typography,
  makeStyles,
} from "@material-ui/core";
// Local
import { SiteLogo } from "../../components";
import {
  sellActions,
  sellSelectors,
  useAction,
  useSelector,
} from "../../state";
import bgSrc from "../../assets/img/sell_bg.png";
import { withSellPageType } from "./hocs";

const useStyles = makeStyles(
  theme => ({
    /** @type {React.CSSProperties} */
    root: {
      backgroundColor: "#000",
      display: "flex",
      justifyContent: "center",
    },
    /** @type {React.CSSProperties} */
    content: {
      display: "flex",
      justifyContent: "center",
      minHeight: "100vh",
      // padding: "48px 120px 65px 120px",
      width: "100vw",
    },
    /** @type {React.CSSProperties} */
    bg: {
      backgroundImage: `url(${bgSrc})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      flex: 1,
      maxWidth: 720,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      paddingTop: 48,
      paddingBottom: 65,
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    /** @type {React.CSSProperties} */
    forms: {
      backgroundColor: "white",
      flex: 1,
      maxWidth: 600,
      minWidth: 300,
      padding: "48px 80px 80px 80px",
      [theme.breakpoints.down("sm")]: {
        padding: "12px 20px 80px 20px",
      },
    },
  }),
  {
    classNamePrefix: "SellPageLayout",
  },
);

const text = {
  returns: { tagline: "The simplest way to return your lease." },
  sell: { tagline: "The simplest way to sell your car." },
};

export const SellPageLayout = React.memo(
  withSellPageType(
    /**
     *
     */
    function SellPageLayout(props) {
      const { dealer: dealerId, sellPageType, pageQuery } = props;
      const classes = useStyles();

      const getDealer = useAction(sellActions.getDealer);
      const dealer = useSelector(sellSelectors.dealer);

      React.useEffect(() => {
        getDealer(dealerId, pageQuery.cid);
      }, [dealerId, pageQuery.cid, getDealer]);

      return (
        <div className={classes.root}>
          <div className={classes.content}>
            <div className={classes.bg}>
              <Box textAlign="center">
                <SiteLogo fill="white" />
              </Box>
              <Box
                color="white"
                fontSize="26px"
                lineHeight="39px"
                textAlign="center"
              >
                {text[sellPageType].tagline}
              </Box>
            </div>
            <div className={classes.forms}>
              <img src={dealer.logoUrl} height={64} alt="Dealer" />
              {props.children}
            </div>
          </div>
        </div>
      );
    },
  ),
);
