import "./polyfill";
import React from "react";
import {
  init as initSentry,
  reactRouterV5Instrumentation,
} from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import ReactDOM from "react-dom";
import { Provider as ReduxProvider } from "react-redux";
import { CssBaseline, ThemeProvider } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFns from "@date-io/date-fns";
// Local
import { REACT_APP_SENTRY_DSN } from "./config";
// import { ErrorSentry } from "./components";
import { history } from "./lib";
import { store } from "./state";
import { telem } from "./telem";

// Example to use service worker / progressive web app (PWA):
// import * as serviceWorker from "./serviceWorker";
// import serviceWorkerConfig from "./serviceWorkerConfig";

import { defaultTheme } from "./themes";
import "./assets/css/styles.css";

import { App } from "./App";

// NOTE: The `store` already comes preloaded slices that where persisted.

/**
 * Startup function.
 */
function main() {
  // store.dispatch(authActions.load());
  startSentry();
  telem.init();
  render();
  registerServiceWorker();
}
/**
 * Service worker registration.
 *
 * Should be registered after a call to `render` **UNLESS you are using
 * `self.clients.claim()` within your service worker.** Otherwise, you want
 * to register late (after a call to `render`) to avoid problems on "low-end
 * mobile devices" (per the docs).
 *
 * 1. [CRA docs on PWA](https://create-react-app.dev/docs/making-a-progressive-web-app)
 * 2. [Reasons to register early](https://developers.google.com/web/fundamentals/primers/service-workers/registration#reasons_to_register_early)
 *
 */
function registerServiceWorker() {
  // Example to implement register service worker:
  // serviceWorker.register(serviceWorkerConfig);
}

function render() {
  // TODO: Add <ErrorSentry> as root.
  ReactDOM.render(
    <ThemeProvider theme={defaultTheme}>
      <MuiPickersUtilsProvider utils={DateFns}>
        <ReduxProvider store={store}>
          <CssBaseline />
          <App />
        </ReduxProvider>
      </MuiPickersUtilsProvider>
    </ThemeProvider>,
    document.getElementById("root"),
  );
}

function startSentry() {
  initSentry({
    dsn: REACT_APP_SENTRY_DSN,
    integrations: [
      new BrowserTracing({
        tracePropagationTargets: [
          "https://api.dealsmgr.com",
          "https://www.autoturnin.com",
          "https://autoturnin.com",
          "https://turnyn.info",
          "https://www.turnyn.app",
        ],
        routingInstrumentation: reactRouterV5Instrumentation(history),
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100% of transactions.
    // We recommend adjusting this value in production.
    tracesSampleRate: 1.0,
  });
}

main();
