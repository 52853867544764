import React from "react";
import {
  Box,
  // Button,
  // Grid,
  // IconButton,
  Link,
  // Menu,
  // MenuItem,
  Paper,
  // Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
// Icons
// Assets
import videoSrc from "../../assets/img/animation.mp4";
// Local
import {
  useLocation,
  // useTimer
} from "../../lib";
import { PageLinkButton, SiteLogo } from "../../components";
import { MainToolbar } from "../../layouts";
// import { Pages } from "../../pages";
import {
  sellActions,
  sellSelectors,
  useAction,
  useSelector,
} from "../../state";
// import { telem } from "../../telem";
// Local assets
import { isMobileAgent } from "../../config";
import { DownIcon } from "./home/DownIcon";
import { GetPaidIcon } from "./home/GetPaidIcon";
import { OfferIcon } from "./home/OfferIcon";
import { SellIcon } from "./home/SellIcon";
import { StepsImage } from "./home/StepsImage";
import { InspectFromHomeIcon } from "./home/InspectFromHomeIcon";
import { useStyles } from "./HomePage.styles";

const faqs = [
  {
    q: "How long till I get my quote?",
    a: "Using Advanced AI, we’ll have a quote for you in less than 24 hours. Promise.",
  },
  {
    q: "Who’s going to pick up my car?",
    a: "That would be us. Once a delivery is scheduled, you’ll get an email notifying you when someone from our team will be heading over.",
  },
  {
    q: "What about my current lease?",
    a: "Fear not. We’ll buy out your car and handle all that paperwork on your desk.",
  },
  {
    q: "When does my offer expire?",
    a: "After you receive an email offer, you have 7 days to accept. If you need some more time, just give us a call and we can probably pull a few strings.",
  },
];

export const HomePage = React.memo(
  /**
   *
   */
  function HomePage() {
    const classes = useStyles();
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

    const location = useLocation();

    const getDealer = useAction(sellActions.getDealer);
    const dealer = useSelector(sellSelectors.dealer);

    React.useEffect(() => {
      getDealer(location.query.dealer || "1001", location.query.cid);
      // telem.logVisit(location);
    }, [location, getDealer]);

    return (
      <div className={classes.root}>
        <div className={classes.content}>
          <MainToolbar />
          <div className={classes.intro}>
            <Typography variant="h3">Make bank for your car</Typography>
            <Typography variant="h5">
              Real numbers. Real offers. Real money.
            </Typography>
          </div>
          <PageLinkButton
            to={`/sell${location.search}`}
            className={classes.sellBtn}
            variant="contained"
            color="primary"
            size="large"
          >
            {/* I noticed in mobile teh button text was to long so it just said sell my car with */}
            {!isMobileAgent || dealer.name
              ? `Sell my car with ${dealer.name}`
              : " Sell my car"}
          </PageLinkButton>
        </div>
        <div className={classes.vframe}>
          <video className={classes.video} autoPlay loop muted playsInline>
            <source src={videoSrc} type="video/mp4" />
          </video>
          <div className={classes.vshadow} />
        </div>
        <div className={classes.tbox}>
          <div className={classes.content}>
            <Box height="20px">
              <DownIcon className={classes.tarrow} />
            </Box>
            <div className={classes.tlayout}>
              <Box paddingTop="60px">
                <Box
                  component={Paper}
                  elevation={24}
                  maxWidth={440}
                  padding="32px 40px 38px 40px"
                  borderRadius="9px"
                >
                  <img src={dealer.logoUrl} height={64} alt="Dealer" />
                  <Box paddingTop="24px" color="#141932" fontSize="16px">
                    "Dave held my hand every step of the way and I have a check
                    to prove it."
                  </Box>
                  <Box
                    paddingTop="57px"
                    fontSize="18px"
                    fontWeight="500"
                    color="#6876FF"
                  >
                    Trish R.
                  </Box>
                </Box>
              </Box>
              <Box maxWidth="560px" marginLeft="32px">
                <Box
                  className={classes.mcenter}
                  fontSize="32px"
                  fontWeight="600"
                  color="#141932"
                  lineHeight="48px"
                  paddingTop="80px"
                >
                  Car lease? Finance? No problem.
                </Box>
                <Box
                  fontSize="20px"
                  color="#141932"
                  lineHeight="30px"
                  paddingTop="8px"
                >
                  The simplest way to sell your car. And don’t even worry about
                  your lease payoff or disposition fees. We got you covered.
                </Box>
              </Box>
            </div>
          </div>
        </div>
        <Box
          className={classes.content}
          paddingTop="80px"
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Box
            id="how-it-works"
            fontSize="32px"
            fontWeight="600"
            textAlign="center"
            paddingTop="40px"
          >
            How it works
          </Box>
          <div className={classes.hiwbox}>
            <Box
              display="flex"
              flexDirection="column"
              paddingRight={isSmall ? undefined : "80px"}
              // paddingTop={isSmall ? "20px" : undefined}
              // height={isSmall ? "400px" : "420px"}
              height={isSmall ? "320px" : "420px"}
              // width="150px"
              alignItems="center"
              justifyContent="space-between"
              // justifyContent={isSmall ? "space-around" : "space-between"}
              // fontSize={isSmall ? "8px" : undefined}
            >
              <OfferIcon
                {...(isSmall
                  ? {
                      width: "27px",
                      height: "32px",
                    }
                  : undefined)}
              />
              <SellIcon
                {...(isSmall
                  ? {
                      width: "32px",
                      height: "33px",
                    }
                  : undefined)}
              />
              <GetPaidIcon
                {...(isSmall
                  ? {
                      width: "36px",
                      height: "33px",
                    }
                  : undefined)}
              />
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              // width="150px"
              // height="404px"
              paddingRight={isSmall ? undefined : "40px"}
              alignItems="center"
            >
              <StepsImage
                {...(isSmall
                  ? {
                      width: "60px",
                      height: "360px",
                    }
                  : undefined)}
              />
            </Box>
            <Box
              flex={1}
              height={isSmall ? "390px" : "420px"}
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              maxWidth="616px"
            >
              <div>
                <Box
                  fontSize="20px"
                  fontWeight="600"
                  color="#141932"
                  paddingBottom="8px"
                  // paddingTop={isSmall ? "12px" : undefined}
                >
                  Get your offer
                </Box>
                <div>
                  Tell us about your car and complete a quick inspection from
                  the comfort of your own home. We’ll get back to you within 24
                  hours with a real offer for your car.
                </div>
              </div>
              <div>
                <Box
                  fontSize="20px"
                  fontWeight="600"
                  color="#141932"
                  paddingBottom="8px"
                  marginTop={isSmall ? "-24px" : undefined}
                >
                  Sell your car
                </Box>
                <div>
                  Like what you see? Accept the offer and schedule pick up.
                </div>
              </div>
              <div>
                <Box
                  fontSize="20px"
                  fontWeight="600"
                  color="#141932"
                  paddingBottom="8px"
                >
                  Get Paid
                </Box>
                <div>Congrats. Here’s your check. Drinks on you.</div>
              </div>
            </Box>
          </div>
        </Box>
        <Box
          className={`${classes.content} ${classes.tlayout}`}
          alignItems="center"
          paddingTop="60px"
        >
          <div>
            <Box
              className={classes.mcenter}
              color="#141932"
              fontSize="32px"
              lineHeight="48px"
              marginTop="16px"
            >
              Inspect from home
            </Box>
            <Box
              color="#141932"
              fontSize="20px"
              lineHeight="30px"
              maxWidth="520px"
              paddingTop="16px"
            >
              No need to bring your car to us. We’ll walk you through some quick
              pictures and you’ll be all set from the comfort of your own phone.
            </Box>
          </div>
          <div>
            <InspectFromHomeIcon />
          </div>
        </Box>
        <Box
          className={classes.content}
          paddingTop="150px"
          paddingBottom="100px"
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Box
            className={classes.mcenter}
            id="faq"
            fontSize="32px"
            fontWeight="600"
            lineHeight="48px"
            color="#141932"
          >
            Frequently asked questions
          </Box>
          <Box maxWidth="1024px">
            {faqs.map((it, i) => (
              <React.Fragment key={i}>
                <Box
                  fontSize="20px"
                  fontWeight="600"
                  lineHeight="30px"
                  color="#141932"
                  paddingTop="40px"
                >
                  {it.q}
                </Box>
                <Box>{it.a}</Box>
              </React.Fragment>
            ))}
          </Box>
        </Box>
        <Box id="about" height="40px" />
        <Box
          className={classes.content}
          bgcolor="#4E50FF"
          paddingTop="40px"
          paddingBottom="80px"
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Box
            fontSize="32px"
            fontWeight="600"
            lineHeight="48px"
            color="#FFFFFF"
            textAlign="center"
          >
            What's turnyn's story?
          </Box>
          <Box maxWidth="800px">
            <Box
              fontSize="18px"
              lineHeight="32px"
              color="#FFFFFF"
              paddingTop="40px"
            >
              Turnyn provides customers a first class experience trading in
              their vehicle. We help customers complete the entire trade in
              process from the comfort of their own home and facilitate a
              seamless purchase from start to finish. In short - we make selling
              your car to your local dealership a piece of cake!
            </Box>
          </Box>
        </Box>
        <Box
          className={`${classes.content} ${classes.tlayout}`}
          bgcolor="#401AFF"
          height="96px"
          paddingTop="80px"
          paddingBottom="80px"
          // paddingBottom="460px"
          display="flex"
          flexDirection="row-reverse"
          alignItems="center"
          justifyContent="space-around"
        >
          <Box fontSize="14px" lineHeight="21px" color="#FFFFFF">
            &copy; Copyright 2021. All rights reserved.
          </Box>
          <div>
            <Link href={`/${window.location.search}`}>
              <SiteLogo height="40px" fill="#FFFFFF" />
            </Link>
          </div>
        </Box>
        <Box height="360px" />
      </div>
    );
  },
);
