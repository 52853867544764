import React from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  // makeStyles,
} from "@material-ui/core";
// Local
import { useField } from "../../lib";

// export const useStyles = makeStyles(
//   theme => ({
//     group: {
//       flexDirection: "row",
//     },
//   }),
//   {
//     classNamePrefix: "CheckboxArrayField",
//   },
// );

export const CheckboxArrayField = React.memo(
  /**
   * A Material-UI `FormControl` and `Checkbox` bound to a `formik` field.
   *
   * @typedef {import("@material-ui/core").FormControlProps} FormControlProps
   * @typedef {object} CheckboxFieldProps
   * @property {Record<string,string>} [classFrom] Object to get `className`
   * from, for the given field `name`.
   * @property {boolean} [error] True if error.
   * @property {string} [helperText] Message to show beneath the control.
   * @property {{label:string,value:any}[]} items
   * @property {number|string} itemWidth
   * @property {string} name
   * @property {string} [label]
   * @property {boolean} [vertical] True to orient checkboxes vertically.
   *
   * @param {CheckboxFieldProps & FormControlProps} props
   */
  function CheckboxArrayField(props) {
    let {
      name,
      classFrom,
      className,
      error,
      helperText,
      items,
      itemWidth,
      label,
      vertical,
      value: _value,
      ...rest
    } = props;
    // const classes = useStyles(props);
    const [fld, meta, helpers] = useField(name);
    error = Boolean(meta.error) || error;
    helperText = meta.error ? meta.error : helperText;
    const itemStyle = itemWidth ? { width: itemWidth } : undefined;

    const onChange = React.useCallback(
      value => {
        return (
          /** @param {React.SyntheticEvent<HTMLInputElement>} e */
          e => {
            const values = fld.value || [];
            if (e.target.checked) {
              // Add
              if (!values.includes(value)) {
                helpers.setValue([...values, value]);
              }
            } else {
              // Remove
              if (values.includes(value)) {
                helpers.setValue(values.filter(v => v !== value));
              }
            }
          }
        );
      },
      [fld, helpers],
    );
    const value = fld.value || [];
    return (
      <FormControl
        name={name}
        component="fieldset"
        error={error}
        className={className || classFrom?.[name]}
        {...rest}
      >
        {label && (
          <FormLabel component="legend" focused={false}>
            {label}
          </FormLabel>
        )}
        <FormHelperText id={`${name}-helper-text`} error={error}>
          {helperText}
        </FormHelperText>
        <FormGroup row={!vertical}>
          {items.map((it, i) => (
            <FormControlLabel
              key={i}
              style={itemStyle}
              control={
                <Checkbox
                  name={`${name}-${i}`}
                  checked={value.includes(it.value)}
                  onChange={onChange(it.value)}
                />
              }
              label={it.label}
            />
          ))}
        </FormGroup>
      </FormControl>
    );
  },
);
