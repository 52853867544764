import type {
  //
  Action,
  Location,
} from "history";
// import axios, {
//   AxiosRequestConfig,
//   // AxiosResponse,
//   // AxiosError,
// } from "axios";
// Local
import { REACT_APP_API_URL } from "./config";
import {
  //
  Navigation,
  parseQueryString,
} from "./lib/routing/Navigation";

// const config: AxiosRequestConfig = {
//   baseURL: REACT_APP_API_URL,
//   headers: {
//     "Content-Type": "application/json",
//   },
//   withCredentials: true,
// };
// const apiClient = axios.create(config);

const logUrl = `${REACT_APP_API_URL}/log`;

export interface LogVisitInfo {
  visitorId?: number;
  dealerId?: number;
  campaignUid?: string;
  dealerUserUid?: string;
  appraisalUid?: string;
  appraisalOfferUid?: string;
  url?: string;
  ipInfo?: string;
}

const postConfig = {
  method: "POST",
  mode: "cors",
  cache: "no-cache",
  credentials: "omit",
  headers: {
    "Content-Type": "application/json",
  },
  redirect: "follow",
} as RequestInit;

function shouldLogAction(action: Action) {
  return action === "PUSH" || action === "REPLACE" || !action;
}

function shouldLogPath(pathname: string) {
  // NOTE: The AcceptOffer page does it's own logging, to get route params...
  return !pathname.includes("/offer/");
}

function navigationActionLogger(location: Location, action: Action) {
  // console.log("NAV", location, action);
  if (!shouldLogAction(action) || !shouldLogPath(location.pathname)) {
    return;
  }
  telem.logVisit(location);
}

export const telem = {
  init() {
    // // console.log("INIT");
    Navigation.history.listen(navigationActionLogger);
    // const winloc = window.location;
    const winloc = window.location;
    if (!shouldLogPath(winloc.pathname)) {
      return;
    }
    telem.logVisit({
      pathname: winloc.pathname,
      search: winloc.search,
      hash: winloc.hash,
      state: window.history.state,
      // query: parseQueryString(winloc.search),
    });
  },
  async logVisit(loc: Location, info?: Partial<LogVisitInfo>) {
    const winloc = window.location;
    const { protocol: prot, hostname: host, port } = winloc;
    const { pathname, search } = loc;
    const query = parseQueryString(search);
    const url = `${prot}//${host}${port ? ":" + port : ""}${pathname}${search}`;
    const visit = {
      ...info,
      // ipInfo, // use seeip.org (okay) or ip-api.com (better)
      url,
    } as LogVisitInfo;

    if (!visit.dealerId) {
      visit.dealerId = parseInt(
        `${query.dealer || process.env.REACT_APP_DEFAULT_DEALER_ID}`,
      );
    }
    if (query.duid) {
      visit.dealerUserUid = query.duid;
    }
    if (query.cid) {
      visit.campaignUid = query.cid;
    }
    // console.log("VISIT", visit);
    try {
      const vid = localStorage.getItem("app_vid");
      let visitorId = vid ? parseInt(vid) : undefined;
      if (visitorId) {
        visit.visitorId = visitorId;
      }
      const res = await fetch(logUrl, {
        ...postConfig,
        body: JSON.stringify(visit),
      });
      const serverData = await res.json();
      if (!visitorId) {
        visitorId = serverData.vid;
        if (visitorId) {
          localStorage.setItem("app_vid", `${visitorId}`);
        }
      }
    } catch (err) {
      console.error(err, visit);
    }
  },
};
