import { Navigation } from "../../lib";
import { SellStartPage } from "./SellStartPage";
import { SellWizardCompletedPage } from "./SellWizardCompletedPage";
import { SellWizardPage } from "./SellWizardPage";

export const SellPages = {
  returns: {
    anon: true,
    path: "/return",
    pathExact: true,
    title: "Lease Return",
    view: SellStartPage,
    url: ({ vin, query }) =>
      !vin
        ? SellPages.returns.path
        : Navigation.url(SellPages.returns.path, {
            query: { vin, ...query },
          }),
  },
  returnsWizard: {
    anon: true,
    path: "/return/vin/:vin",
    pathExact: true,
    title: "Lease Return Form",
    view: SellWizardPage,
    url: ({ vin = "", query }) =>
      Navigation.url(SellPages.returnsWizard.path, {
        params: { vin },
        query: { ...query },
      }),
  },
  returnsWizardCompleted: {
    anon: true,
    path: "/return/thank-you/:vin",
    title: "Lease Return Completed",
    view: SellWizardCompletedPage,
    url: ({ vin, query }) =>
      Navigation.url(SellPages.returnsWizardCompleted.path, {
        params: { vin },
        query: { ...query },
      }),
  },
  sell: {
    anon: true,
    path: "/sell",
    pathExact: true,
    title: "Sell",
    view: SellStartPage,
    url({ vin, query }) {
      return !vin
        ? SellPages.sell.path
        : Navigation.url(SellPages.sell.path, {
            query: { vin, ...query },
          });
    },
  },
  sellWizard: {
    anon: true,
    path: "/sell/vin/:vin",
    pathExact: true,
    title: "Appraisal",
    view: SellWizardPage,
    url: ({ vin = "", query }) =>
      Navigation.url(SellPages.sellWizard.path, {
        params: { vin },
        query: { ...query },
      }),
  },
  sellWizardCompleted: {
    anon: true,
    path: "/sell/thank-you/:vin",
    title: "Thank You",
    view: SellWizardCompletedPage,
    url: ({ vin, query }) =>
      Navigation.url(SellPages.sellWizardCompleted.path, {
        params: { vin },
        query: { ...query },
      }),
  },
};
