import { useCallback } from "react";
import {
  useDispatch,
  useSelector as useReduxSelector,
  TypedUseSelectorHook,
} from "react-redux";
// Local
import { AppState } from "./types";

export { bindActionCreators } from "redux";
export { connect, useDispatch, useStore } from "react-redux";

export const useSelector: TypedUseSelectorHook<AppState> = useReduxSelector;

//
// useAction overloads. See:
// https://github.com/reduxjs/react-redux/issues/1179#issuecomment-461659528
//

/** `useAction` overload for thunk action creators. */
function useAction<
  T extends (
    ...args: any[]
  ) => (dispatch: any, getState: () => AppState, extraArgument: any) => any
>(
  actionCreator: T,
  deps?: ReadonlyArray<unknown>,
): T extends (...args: infer A) => (...args: any[]) => infer R
  ? (...args: A) => R
  : never;
/** `useAction` overload for regular action creators */
function useAction<T extends (...args: any[]) => any>(
  actionCreator: T,
  deps?: ReadonlyArray<unknown>,
): T;
/** `useAction` overload for a regular action. */
function useAction<T extends { type: string }>(
  action: T,
  deps?: ReadonlyArray<unknown>,
): () => T;

/** Creates a callback function to dispatch the given action. */
function useAction(action: any) {
  const dispatch = useDispatch();
  return useCallback((...args) => dispatch(action(...args)), [
    dispatch,
    action,
  ]);
}

export { useAction };
