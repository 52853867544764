export const SellWizardSections = [
  "vehicle-info",
  // "vehicle-history",
  // "interior",
  // "exterior",
  // "mechanical",
  "contact",
] as const;

export type SellWizardSectionKeys = typeof SellWizardSections[number];

export type SellWizardForms = Record<SellWizardSectionKeys, any> | {};
