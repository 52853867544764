/**
 * @file This file is used to normalize environment variables and provide
 * intellisense/autocomplete for them. Import your settings from this file
 * instead of directly from `process.env`.
 */

/** Host name used to detect production mode. */
const REACT_APP_PROD_HOSTNAME = process.env.REACT_APP_PROD_HOSTNAME;
/**
 * True if the app is in production mode.
 * NOTE: We don't use process.env.NODE_ENV to test for this
 * because Create-React-App uses "production" for every other non-development
 * environment.
 */
const __PROD__ = window.location.hostname === REACT_APP_PROD_HOSTNAME;

/** Base URL of the API. */
let REACT_APP_API_URL = process.env.REACT_APP_API_URL;
/** URL to the company website. */
const REACT_APP_COMPANY_SITE_URL = process.env.REACT_APP_COMPANY_SITE_URL;
/** DSN URL for the error telemetry API. */
const REACT_APP_ERRORS_DSN = process.env.REACT_APP_ERRORS_DSN;
/** True to report errors during development. */
const REACT_APP_ERRORS_DEV = process.env.REACT_APP_ERRORS_DEV === "true";
/** Key to store redux state under localStorage. */
const REACT_APP_PERSIST_KEY = process.env.REACT_APP_PERSIST_KEY;
/** Sentry error reporting string */
const REACT_APP_SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
/** Site title string. */
const REACT_APP_SITE_TITLE = process.env.REACT_APP_SITE_TITLE;
/** Package version string from the package.json file. */
const REACT_APP_VERSION = process.env.REACT_APP_VERSION;

if (process.env.NODE_ENV === "development") {
  // We cannot use the localhost API server to test on mobile devices.
  if (window.location.hostname !== "localhost") {
    REACT_APP_API_URL = REACT_APP_API_URL.replace(
      "http://localhost",
      `http://${window.location.hostname}`,
    );
  }
}

export {
  // Environment
  __PROD__,
  // Application
  REACT_APP_API_URL,
  REACT_APP_COMPANY_SITE_URL,
  REACT_APP_ERRORS_DSN,
  REACT_APP_ERRORS_DEV,
  REACT_APP_PERSIST_KEY,
  REACT_APP_PROD_HOSTNAME,
  REACT_APP_SENTRY_DSN,
  REACT_APP_SITE_TITLE,
  REACT_APP_VERSION,
};

/** True if the browser has been detected as IE11. */
export const ie11 = !!window.MSInputMethodContext && !!document.documentMode;
/**
 * True if iOS (iPhone, iPad, iPod) has been detected.
 * - See [Material-UI SwipeableDrawer](https://material-ui.com/components/drawers/#swipeable-temporary-drawer)
 */
export const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
/** True if Android has been detected. */
export const Android = /android/i.test(navigator.userAgent);
/** True if the user agent matched either `Android` or `iOS` */
export const isMobileAgent = Android || iOS;
