import React from "react";
import { Box, Button, makeStyles } from "@material-ui/core";
// Local
import { debounce, useFormikContext } from "../../../lib";
import { SellWizardContext } from "../context";
import { withSellPageType } from "../hocs";

const text = {
  returns: {
    continue: "Continue",
    submit: "Start photo inspection",
  },
  sell: {
    continue: "Continue",
    getOffer: "See your offer",
    submit: "Start photo inspection",
  },
};

const useStyles = makeStyles(
  theme => ({
    submit: {
      backgroundColor: "#4E50FF",
      color: "#FFFFFF",
      minHeight: 48,
      padding: "13px 24px",
    },
    later: {
      backgroundColor: "#EFF1FB",
      color: "#141932",
      minHeight: 48,
      marginLeft: 16,
      padding: "13px 24px",
    },
  }),
  {
    classNamePrefix: "SellWizardContinue",
  },
);

export const WizardFormActions = React.memo(
  withSellPageType(
    /**
     * @typedef {object} WizardFormActionsProps
     * @property {boolean} [disabled]
     * @property {boolean} [isFirstSection]
     * @property {boolean} [isLastSection]
     */
    function WizardFormActions({
      // children,
      disabled,
      isFirstSection,
      isLastSection,
      isLeaseReturn,
      pageQuery: { ao: autoOffer },
      sellPageType,
    }) {
      const classes = useStyles();
      const { busy, nextSection, prevSection, setBusy } =
        React.useContext(SellWizardContext);
      const { submitForm, validateForm } = useFormikContext();
      const onClickContinue = React.useCallback(
        /** @param {React.SyntheticEvent<HTMLButtonElement>} e */
        async e => {
          e.preventDefault();
          if (busy) {
            return;
          }
          setBusy(true);
          try {
            const submitOptions =
              e.currentTarget?.type === "submit" ? undefined : { later: true };
            await submitForm();
            const errors = await validateForm();
            if (Object.keys(errors).length > 0) {
              return;
            }
            await nextSection(submitOptions);
          } catch (ex) {
            requestAnimationFrame(() => {
              window.alert("There was an error. Please try again.");
            });
          } finally {
            setBusy(false);
          }
        },
        [busy, setBusy, submitForm, validateForm, nextSection],
      );
      const onClickGoBack = React.useCallback(
        /** @param {React.SyntheticEvent<HTMLElement>} e */
        e => {
          prevSection();
        },
        [prevSection],
      );
      return (
        <Box paddingTop="40px">
          <Box display="flex">
            <Button
              className={classes.submit}
              variant="contained"
              disableElevation
              type="submit"
              onClick={onClickContinue}
              disabled={busy || disabled}
              size="large"
            >
              {!isLastSection
                ? text[sellPageType].continue
                : autoOffer
                ? text.sell.getOffer
                : text[sellPageType].submit}
            </Button>
            {isLastSection && !autoOffer && (
              <Button
                size="large"
                className={classes.later}
                variant="contained"
                disableElevation
                onClick={onClickContinue}
                disabled={busy || disabled}
              >
                I'll do it later
              </Button>
            )}
          </Box>
          {!isFirstSection && (
            <Box
              color="#4E50FF"
              fontSize="14px"
              fontWeight="500"
              lineHeight="18px"
              paddingTop="16px"
            >
              <Button color="inherit" variant="text" onClick={onClickGoBack}>
                ← Go back
              </Button>
            </Box>
          )}
        </Box>
      );
    },
  ),
);

export const AutoSaveWizardForm = React.memo(
  /**
   *
   */
  function AutoSaveWizardForm() {
    const frm = useFormikContext();
    const { saveForm } = React.useContext(SellWizardContext);
    const { values } = frm;

    const initialized = React.useRef(false);

    const save = React.useMemo(
      () => debounce(values => saveForm(values), 500),
      [saveForm],
    );

    React.useEffect(() => {
      if (!initialized.current) {
        initialized.current = true;
        return;
      }
      save(values);
    }, [values, save]);

    return null;
  },
);
