import React from "react";
import {
  //
  Box,
  Button,
  // Typography,
  makeStyles,
} from "@material-ui/core";
// Images
import sparklesL from "../../assets/img/sparkles-l.png";
// import sparklesR from "../../assets/img/sparkles-r.png";
import sparklesBR from "../../assets/img/sparkles-br.png";
import sparklesTL from "../../assets/img/sparkles-tl.png";
import sparklesTR from "../../assets/img/sparkles-tr.png";
// Local
import { Navigation, inIframe } from "../../lib";
import { withSellPageType } from "./hocs";
import { SellPageLayout } from "./SellPageLayout";
import { SellPages } from "./index";

// TODO: This module is terrible. It should be reorganized into several
// different components instead of using this `text` structure...

const text = {
  offer: {
    title: "Here it is!",
    body:
      "One thing left to do. Complete this quick virtual inspection and " +
      "get instant cash for your car!",
    tagline: "You may leave this page now. We'll be in touch",
  },
  pending: {
    title: "Oh, bummer.",
    heading:
      "Unfortunately, we still need some more info to get you an " +
      "estimated offer.",
    body:
      "Want an instant cash offer?? Complete this quick virtual " +
      "inspection to see how much instant cash you can make!",
  },
  returns: {
    title: "Thank you for submitting your lease return.",
    body:
      "Almost done! The last thing we need is an inspection of your car." +
      " Please click the link below to begin your mobile inspection.",
    tagline: "Hang tight while we review your information.",
  },
  sell: {
    title: "Thank you!",
    body:
      "Almost done! The last thing we need is an inspection of your car." +
      " Please click the link below to begin your mobile inspection.",
    tagline: "You may leave this page now. We'll be in touch",
  },
};

const useStyles = makeStyles(
  theme => ({
    later: {
      backgroundColor: "#EFF1FB",
      color: "#141932",
      minHeight: 48,
      marginLeft: 16,
      padding: "13px 24px",
    },
  }),
  {
    classNamePrefix: "SellWizardCompletedPage",
  },
);

const OfferBox = React.memo(function OfferBox({ offer, offerMax }) {
  return (
    <Box
      ml="-50px"
      // ml={offerMax ? "-40px" : undefined}
      mr="-50px"
      // mr={offerMax ? "-40px" : undefined}
      pt="8px"
    >
      <Box display="flex" justifyContent="space-between">
        <img src={sparklesTL} alt="sparkles" />
        <img src={sparklesTR} alt="sparkles" />
      </Box>
      <Box display="flex">
        <img src={sparklesL} alt="sparkles" />
        <Box
          color="#401AFF"
          fontSize="48px"
          fontWeight="600"
          letterSpacing="0"
          flex="1"
          textAlign="center"
        >
          {offerMax ? `${offer} - ${offerMax}` : offer}
        </Box>
        {/* <img src={sparklesR} alt="sparkles" /> */}
      </Box>
      <Box display="flex" justifyContent="flex-end" mt="-30px">
        <img src={sparklesBR} alt="sparkles" />
      </Box>
    </Box>
  );
});

export const SellWizardCompletedPage = React.memo(
  withSellPageType(
    /**
     *
     */
    function SellWizardCompletedPage({
      isLeaseReturn,
      pageParams: params,
      pageQuery: query,
      pageQuery: {
        ao: autoOffer,
        dealer = process.env.REACT_APP_DEFAULT_DEALER_ID,
        inspectionUrl,
        offer = "",
        max: offerMax = "",
      },
      sellPageType,
    }) {
      const classes = useStyles();

      const iframe = inIframe();

      const onClickLater = React.useCallback(
        /** @param {React.SyntheticEvent<HTMLElement>} e */
        e => {
          const completedPage = isLeaseReturn
            ? SellPages.returnsWizardCompleted
            : SellPages.sellWizardCompleted;
          const {
            ao: _autoOffer,
            inspectionUrl: _iurl,
            offer: _offer,
            max: _max,
            ...restQuery
          } = query;
          Navigation.go(completedPage, {
            query: restQuery,
            params,
          });
        },
        [isLeaseReturn, params, query],
      );

      return (
        <SellPageLayout dealer={dealer}>
          <Box
            color="#141932"
            fontSize="40px"
            fontWeight="600"
            lineHeight="60px"
            paddingTop="24px"
          >
            {autoOffer
              ? offer
                ? text.offer.title
                : text.pending.title
              : text[sellPageType].title}
          </Box>
          {autoOffer && offer && <OfferBox offer={offer} offerMax={offerMax} />}
          {autoOffer && !offer && (
            <Box
              color="#141932"
              fontSize="24px"
              fontWeight={600}
              lineHeight="26px"
              pt="8px"
            >
              {text.pending.heading}
            </Box>
          )}
          {inspectionUrl ? (
            <Box
              color="#141932"
              fontSize="18px"
              lineHeight="30px"
              paddingTop="8px"
            >
              {autoOffer && offer && text.offer.body}
              {autoOffer && !offer && text.pending.body}
              {!autoOffer && text[sellPageType].body}
              <br />
              <br />
              <Button
                color="primary"
                size="large"
                variant="contained"
                href={inspectionUrl}
                target={iframe ? "_blank" : undefined}
              >
                Start photo inspection
              </Button>
              <Button
                size="large"
                className={classes.later}
                variant="contained"
                disableElevation
                onClick={onClickLater}
                // disabled={busy || disabled}
              >
                I'll do it later
              </Button>
            </Box>
          ) : (
            <Box
              color="#141932"
              fontSize="20px"
              lineHeight="30px"
              paddingTop="8px"
            >
              {autoOffer && offer && text.offer.tagline}
              {autoOffer && !offer && text.pending.tagline}
              {!autoOffer && text[sellPageType].tagline}
            </Box>
          )}
        </SellPageLayout>
      );
    },
  ),
);
