import React from "react";
import {
  //
  Box,
  // Grid,
  // makeStyles,
} from "@material-ui/core";
// Local
import {
  //
  // AutocompleteField,
  // CheckboxField,
  // DollarField,
  Field,
  PhoneField,
  SelectField,
  SelectStateField,
  ToggleButtonsField,
} from "../../../components";
import {
  //
  Form,
  useFormik,
  useOnMountAsync,
  yup,
} from "../../../lib";
import {
  dealerSettingIds,
  sellActions,
  sellSelectors,
  useAction,
  usePrefill,
  useSelector,
} from "../../../state";
import { SellWizardContext } from "../context";
import {
  // AutoSaveWizardForm,
  WizardFormActions,
} from "../components/SellWizardParts";

// const useStyles = makeStyles(
//   theme => ({
//     root: {
//       // margin: 0,
//     },
//   }),
//   {
//     classNamePrefix: "ContactForm",
//   },
// );

// const sellItems = [
//   { title: "When I find a replacement", id: 1 },
//   { title: "When I get the right price", id: 2 },
//   { title: "Within the next 30 days", id: 3 },
//   { title: "Not actively looking to sell", id: 4 },
//   { title: "I'm not sure", id: 5 },
// ];

const defaultValues = {
  returns: {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
  },
  sell: {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    affiliateId: null,
    // altInspector: false,
    altEmail: null,
    altPhone: null,
  },
};
const altValidation = {
  altInspector: yup.boolean().required("This field is required."),
  altEmail: yup.string().when("altInspector", {
    is: true,
    then: yup
      .string()
      .required("Alternate email is required.")
      .email("Please enter a valid email."),
    otherwise: yup.string().nullable(),
  }),
  altPhone: yup.string().nullable(),
};
const validation = {
  returns: yup.object({
    firstName: yup.string().required("First name is required."),
    lastName: yup.string().required("Last name is required."),
    email: yup
      .string()
      .required("Email is required.")
      .email("Please enter a valid email."),
    phone: yup.string(),
    address1: yup.string().required("Address is required."),
    address2: yup.string(),
    city: yup.string().required("City is required."),
    state: yup.string().required("State is required."),
    zip: yup.string().required("Zip is required."),
  }),
  sell: yup.object({
    firstName: yup.string().required("First name is required."),
    lastName: yup.string().required("Last name is required."),
    email: yup
      .string()
      .required("Email is required.")
      .email("Please enter a valid email."),
    phone: yup.string(),
    affiliateId: yup.number().nullable(),
  }),
  sellWithAffil: yup.object({
    firstName: yup.string().required("First name is required."),
    lastName: yup.string().required("Last name is required."),
    email: yup
      .string()
      .required("Email is required.")
      .email("Please enter a valid email."),
    phone: yup.string(),
    affiliateId: yup
      .number()
      .required("Affiliate is required.")
      .typeError("Affiliate is required."),
  }),
};

export const ContactForm = React.memo(
  /**
   *
   */
  function ContactForm(props) {
    // const classes = useStyles();
    const {
      dealer: dealerId,
      wizard: { forms },
      query,
      saveForm,
    } = React.useContext(SellWizardContext);
    const dealer = useSelector(sellSelectors.dealer);
    const getAffiliates = useAction(sellActions.getAffiliates(dealerId));

    const prefill = usePrefill(query.pf);

    const {
      allowAlt,
      initialValues,
      validationSchema,
      d2d,
      hasAffiliates,
      requireAffiliate,
    } = React.useMemo(() => {
      const { defaultPhone, isLeaseReturn, sellPageType } = props;
      const defaults = defaultValues[sellPageType];
      /** Dealer to dealer */
      const d2d = dealer?.camp?.type === 4;
      const hasAffiliates = !!dealer.ds[dealerSettingIds.affiliatesEnabled];
      const allowAlt =
        !!dealer.ds[dealerSettingIds.allowAlternateInspector] && !isLeaseReturn;
      const requireAffiliate =
        !!dealer.ds[dealerSettingIds.appraisalAffiliateRequired];
      const initialValues = {
        ...defaults,
        firstName: prefill?.firstName ?? defaults.firstName,
        lastName: prefill?.lastName ?? defaults.lastName,
        email: prefill?.email ?? defaults.email,
        phone: defaultPhone ?? prefill?.phone ?? "",
        ...(isLeaseReturn
          ? {
              address1: prefill?.address1 ?? "",
              address2: prefill?.address2 ?? "",
              city: prefill?.city ?? "",
              state: prefill?.state ?? "",
              zip: prefill?.zip ?? "",
            }
          : {}),
        ...forms.contact,
      };
      let validationSchema =
        sellPageType === "sell" && hasAffiliates && requireAffiliate
          ? validation.sellWithAffil
          : validation[sellPageType];
      if (d2d) {
        // Dealer to dealer
        validationSchema = validationSchema.shape({
          vendorName: yup.string().required("Dealer name is required."),
        });
        initialValues.vendorName = "";
      }
      if (allowAlt) {
        validationSchema = validationSchema.shape({
          ...altValidation,
        });
      }
      return {
        allowAlt,
        initialValues,
        validationSchema,

        d2d,

        hasAffiliates,
        requireAffiliate,
      };
    }, [props, dealer, forms.contact, prefill]);

    const [affiliates, setAffiliates] = React.useState(
      requireAffiliate
        ? [
            {
              id: null,
              name: "",
            },
          ]
        : [],
    );
    const { isLeaseReturn } = props;

    const frm = useFormik({
      initialValues,
      validationSchema,
      validateOnBlur: false,
      validateOnChange: false,
      async onSubmit(values, helpers) {
        await saveForm(values);
      },
    });

    useOnMountAsync(async state => {
      const affiliates = await getAffiliates();
      if (!state.mounted) return;
      setAffiliates(affiliates);
    });

    return (
      <Form form={frm} promptOnExit={false}>
        <Box paddingTop="32px">
          <Field
            name="firstName"
            label="First name"
            fullWidth
            required
            autoFocus
          />
        </Box>
        <Box paddingTop="32px">
          <Field name="lastName" label="Last name" fullWidth required />
        </Box>
        <Box paddingTop="32px">
          <Field name="email" label="Email" type="email" fullWidth required />
        </Box>
        <Box paddingTop="32px">
          <PhoneField name="phone" label="Phone number" fullWidth />
        </Box>
        {isLeaseReturn ? (
          <>
            <Box
              paddingTop="32px"
              color="#141932"
              fontSize="14px"
              fontWeight="600"
              lineHeight="21px"
            >
              Address
            </Box>
            <Box paddingTop="32px">
              <Field name="address1" label="Address 1" fullWidth required />
            </Box>
            <Box paddingTop="32px">
              <Field name="address2" label="Address 2" fullWidth />
            </Box>
            <Box paddingTop="32px">
              <Field name="city" label="City" fullWidth required />
            </Box>
            <Box paddingTop="32px">
              <SelectStateField name="state" label="State" fullWidth required />
            </Box>
            <Box paddingTop="32px">
              <Field
                name="zip"
                label="Zip"
                fullWidth
                required
                pattern="[0-9]*"
                inputMode="numeric"
              />
            </Box>
          </>
        ) : hasAffiliates ? (
          <Box paddingTop="16px">
            {/* <Field name="financeBank" label="Bank name" fullWidth required /> */}
            <SelectField
              name="affiliateId"
              label="Affiliate"
              emptyEnabled={!requireAffiliate}
              emptyValue={null}
              items={affiliates}
              fullWidth
              itemKey="id"
              itemText="name"
              required={requireAffiliate}
            />
          </Box>
        ) : null}
        {d2d && (
          <Box paddingTop="32px">
            <Field name="vendorName" label="Dealer name" fullWidth required />
          </Box>
        )}
        {allowAlt && (
          <>
            <Box paddingTop="32px">
              <ToggleButtonsField
                label="Vehicle not with you? Would you like someone else to take pictures of your vehicle instead?"
                name="altInspector"
                fullWidth
                yesNo
                required
                // disabled={loading}
              />
            </Box>
            {frm.values.altInspector && (
              <>
                <Box paddingTop="32px">
                  <Field
                    name="altEmail"
                    label="Email for inspection"
                    type="email"
                    fullWidth
                    required
                  />
                </Box>
                <Box paddingTop="32px">
                  <PhoneField
                    name="altPhone"
                    label="Phone number for inspection"
                    fullWidth
                  />
                </Box>
              </>
            )}
          </>
        )}
        {/* <AutoSaveWizardForm name="contact" /> */}
        <WizardFormActions isLastSection />
      </Form>
    );
  },
);
