import React from "react";
// Local
import { useLocation } from "../../lib";
// import { telem } from "../../telem";
import { SellPages } from "./";

export function withSellPageType(
  WrappedComponent: React.ComponentType<{
    isLeaseReturn: boolean;
    pageParams: Record<string, string>;
    pageQuery: Record<
      "ao" | "dealer" | "phone" | "email" | "max" | "offer",
      string
    >;
    sellPageType: "returns" | "sell";
  }>,
) {
  function WithSellPageType(props) {
    const loc = useLocation();
    const { pathname, params, query } = loc;
    const isLeaseReturn = pathname?.startsWith(SellPages.returns.path);
    const sellPageType = isLeaseReturn ? "returns" : "sell";

    // React.useEffect(() => {
    //   console.log("WithSellPageType"); // <-- CAUSES DOUBLE TELEM LOGGING!
    //   telem.logVisit(loc);
    // }, [loc]);

    return (
      <WrappedComponent
        isLeaseReturn={isLeaseReturn}
        pageParams={params}
        pageQuery={query}
        sellPageType={sellPageType}
        {...props}
      />
    );
  }
  WithSellPageType.displayName =
    WrappedComponent.displayName || WrappedComponent.name || "Component";
  return WithSellPageType;
}
