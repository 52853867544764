import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import {
  KEY_PREFIX,
  persistCombineReducers,
  persistStore,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
// Local
import { REACT_APP_PERSIST_KEY } from "../config";
import appStates from "./states";

const persistKey = REACT_APP_PERSIST_KEY || "app";

// Map appState reducers.
const reducerMap = (function mapStates() {
  /** Slice names to prevent from storage. */
  const noPersist: string[] = [];
  /** Each sub-reducer function by slice name. */
  const reducers: any = {};
  /** States preloaded from localStorage by slice name. */
  let preloadedState: any;

  // Preload state
  const storageObject = (function preloadState() {
    // This is only possible since we're using localStorage which we can access
    // synchronously. It's simpler than using `redux-persist/PersistGate`.
    const storageKey = `${KEY_PREFIX}${persistKey}`;
    const storageValue = localStorage.getItem(storageKey);
    return storageValue ? JSON.parse(storageValue) : undefined;
  })();

  appStates.forEach(appState => {
    const {
      name,
      persist: shouldPersist = false,
      preload: shouldPreload = true,
    } = appState;
    if (!shouldPersist) {
      noPersist.push(name);
    } else if (shouldPreload && storageObject && storageObject[name]) {
      if (preloadedState === undefined) preloadedState = {};
      preloadedState[name] = JSON.parse(storageObject[name]);
    }
    reducers[name] = appState.reducer;
  });
  return {
    noPersist,
    reducers,
    preloadedState,
  };
})();

const persistOptions: any = {
  blacklist: reducerMap.noPersist,
  key: persistKey,
  storage,
};
if (process.env.NODE_ENV === "development") {
  persistOptions.debug = true;
}
const rootReducer = persistCombineReducers(persistOptions, reducerMap.reducers);

export const store = configureStore({
  middleware: getDefaultMiddleware({
    serializableCheck: false,
    immutableCheck: {
      // Allow more time for immutability checks on large Redux state.
      // See https://redux-toolkit.js.org/api/immutabilityMiddleware
      // The default is 32 (milliseconds).
      warnAfter: 128,
    },
  }),
  preloadedState: reducerMap.preloadedState,
  reducer: rootReducer,
});

export const persistor = persistStore(store);
