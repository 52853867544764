import React from "react";

export const SellIcon = React.memo(
  /**
   * @param {React.SVGProps<SVGSVGElement>} param0
   */
  function SellIcon({
    height = "110px",
    width = "107px",
    viewBox = "0 0 107 110",
    fill = "#4E50FF",
    ...props
  }) {
    return (
      <svg width={width} height={height} viewBox={viewBox} {...props}>
        <title>Sell your car</title>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-280.000000, -1549.000000)">
            <g transform="translate(0.000000, 1256.000000)">
              <g transform="translate(280.000000, 293.000000)">
                <g>
                  <rect
                    fill="#B5C1FF"
                    x="0"
                    y="0"
                    width="80"
                    height="80"
                    rx="3.55555556"
                  ></rect>
                  <rect
                    stroke="#192740"
                    x="16"
                    y="16"
                    width="90"
                    height="80"
                    rx="2.66666667"
                  ></rect>
                  <rect
                    fill="#FFFFFF"
                    x="20"
                    y="20"
                    width="82"
                    height="72"
                    rx="2.66666667"
                  ></rect>
                  <rect
                    fill="#DADFF6"
                    x="28"
                    y="38"
                    width="12"
                    height="12"
                    rx="1"
                  ></rect>
                  <path
                    d="M21,20 L101,20 C101.552285,20 102,20.4477153 102,21 L102,32 L102,32 L20,32 L20,21 C20,20.4477153 20.4477153,20 21,20 Z"
                    fill={fill}
                  ></path>
                  <rect
                    fill="#DADFF6"
                    x="28"
                    y="56"
                    width="12"
                    height="12"
                    rx="1"
                  ></rect>
                  <rect
                    fill="#DADFF6"
                    x="46"
                    y="38"
                    width="12"
                    height="12"
                    rx="1"
                  ></rect>
                  <rect
                    fill="#DADFF6"
                    x="46"
                    y="56"
                    width="12"
                    height="12"
                    rx="1"
                  ></rect>
                  <rect
                    fill="#DADFF6"
                    x="64"
                    y="38"
                    width="12"
                    height="12"
                    rx="1"
                  ></rect>
                  <rect
                    fill="#DADFF6"
                    x="64"
                    y="56"
                    width="12"
                    height="12"
                    rx="1"
                  ></rect>
                  <rect
                    fill={fill}
                    x="82"
                    y="38"
                    width="12"
                    height="12"
                    rx="1"
                  ></rect>
                  <rect
                    fill="#DADFF6"
                    x="82"
                    y="56"
                    width="12"
                    height="12"
                    rx="1"
                  ></rect>
                  <rect
                    fill="#DADFF6"
                    x="28"
                    y="74"
                    width="12"
                    height="12"
                    rx="1"
                  ></rect>
                  <rect
                    fill="#DADFF6"
                    x="46"
                    y="74"
                    width="12"
                    height="12"
                    rx="1"
                  ></rect>
                  <rect
                    fill="#DADFF6"
                    x="64"
                    y="74"
                    width="12"
                    height="12"
                    rx="1"
                  ></rect>
                  <rect
                    fill="#DADFF6"
                    x="82"
                    y="74"
                    width="12"
                    height="12"
                    rx="1"
                  ></rect>
                </g>
                <g transform="translate(31.280773, 90.666667)"></g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  },
);
