import React from "react";
import { Popper, Typography } from "@material-ui/core";
// Local
import { states } from "../../assets/data/us_states";
import { AutocompleteField } from "./AutocompleteField";

const items = states.map(state => ({
  id: state.abbr,
  name: state.abbr,
  fullName: state.name,
}));
/**
 * Render items so they don't wrap.
 * See https://material-ui.com/components/autocomplete/#virtualization
 */
function renderItem(item) {
  return (
    <Typography noWrap>
      {item.name} - {item.fullName}
    </Typography>
  );
}
const popperStyle = { width: "fit-content" };
/**
 * Render popup so it is wider than the TextField.
 * See https://stackoverflow.com/questions/63579345/how-can-i-change-the-width-of-material-ui-autocomplete-popover
 * @param {import("@material-ui/core").PopperProps} props
 */
function StateFieldPopper(props) {
  return <Popper {...props} style={popperStyle} placement="bottom-start" />;
}
/**
 * @typedef {import("@material-ui/core").TextFieldProps} TextFieldProps
 * @typedef {object} SelectFieldProps
 * @property {React.ComponentType} [component] `TextField` compatible component.
 * @property {boolean} [disableUnderline]
 * @property {boolean} [emptyEnabled] `true` to allow picking a the empty item.
 * @property {string | boolean} [emptyText] `<>&nbsp;</>` if `emptyEnabled` else
 * `undefined`. Or set `true` to display default empty text of `<>&nbsp;</>`.
 * @property {string} [emptyValue] `""`
 * @property {string} [itemKey] Item key property. `"id"`
 * @property {string} [itemText] Item text property. `"title"`
 * @property {string} [itemValue] Item value property. `"id"`
 * @property {MenuItemProps} [itemProps] Menu item props to pass to each item.
 * @property {{id:string|number,title:string,value:string|number}[]} [items] The
 * items list to derive menu items from.
 *
 * @param {SelectFieldProps & TextFieldProps} param0
 */
export function SelectStateField({
  label = "State",
  name = "state",
  required,
  ...props
}) {
  return (
    <AutocompleteField
      name={name}
      label={label}
      itemsList={items}
      renderOption={renderItem}
      PopperComponent={StateFieldPopper}
      // Don't wrap the list item text.
      disableListWrap
      TextFieldProps={required ? requiredProps : undefined}
      {...props}
    />
  );
}

const requiredProps = {
  required: true,
};
