import React from "react";
import {
  //
  Box,
  InputAdornment,
} from "@material-ui/core";
// Local
import {
  //
  AutocompleteField,
  // CheckboxArrayField,
  CheckboxField,
  DateField,
  // DollarField,
  Field,
  NumberField,
  PageLink,
  SelectField,
  ToggleButtonsField,
} from "../../../components";
import {
  //
  Form,
  isValidNorthAmericanVin,
  // Navigation,
  useFormik,
  yup,
} from "../../../lib";
import { sellActions, useAction, usePrefill } from "../../../state";
import { SellWizardContext } from "../context";
import {
  // AutoSaveWizardForm,
  WizardFormActions,
} from "../components/SellWizardParts";
import { SellPages } from "../index";

/** @type {Record<string,{financeBankNum?:boolean;maturityDate?:boolean;}>} */
const bankReqs = {
  "chase bank": {
    financeBankNum: true,
  },
  "honda financial": {
    financeBankNum: true,
  },
  "chrysler capital": {
    maturityDate: true,
  },
};

// const featureItems = [
//   { label: "Sunroof", value: 1 },
//   { label: "Navigation", value: 2 },
// ];

// const hasKeysItems = [
//   { label: "1", value: 1 },
//   { label: "2+", value: 2 },
// ];

const ownerStatusItems = [
  { label: "I own it", value: 1 },
  { label: "Finance", value: 2 },
  { label: "Lease", value: 3 },
];

const defaultValues = {
  returns: {
    vin: "",
    trim: "",
    vehicle: {
      year: 0,
      make: "",
      model: "",
      trim: "",
      subSeries: "",
    },
    mileage: "",
    // color: "",
    // hasKeys: "",
    // featureIds: [],
    // packageNotes: "",
    ownerStatusId: 3, // Lease
    financeBankUnknown: false,
    financeBank: "",
    financeBankNum: "",
    maturityDate: null,
  },
  sell: {
    vin: "",
    trim: "",
    vehicle: {
      year: 0,
      make: "",
      model: "",
      trim: "",
      subSeries: "",
    },
    mileage: "",
    // color: "",
    // hasKeys: "",
    // featureIds: [],
    // packageNotes: "",
    ownerStatusId: null,
    financeBankUnknown: false,
    financeBank: "",
    financeBankNum: "",
    maturityDate: null,
  },
};

const validationSchema = yup.object({
  vin: yup.string().required("A VIN is required."),
  trim: yup.string().required("A trim is required."),
  vehicle: yup.object({
    year: yup.number(),
    make: yup.string(),
    model: yup.string(),
    trim: yup.string(),
    subSeries: yup.string(),
  }),
  mileage: yup.number().required("Mileage is required."),
  // color: yup.string().required("Color is required."),
  // hasKeys: yup.number().required("Key condition is required."),
  // accident: yup.boolean().required("Accident status is required."),
  // featureIds: yup.array(),
  // packageNotes: yup.string(),
  ownerStatusId: yup
    .number()
    .nullable()
    .required("Ownership status is required."),
  financeBankUnknown: yup.boolean(),
  financeBank: yup.string().when(["financeBankUnknown", "ownerStatusId"], {
    is: (financeBankUnknown, ownerStatusId) =>
      !financeBankUnknown && (ownerStatusId === 2 || ownerStatusId === 3),
    then: yup.string().required("Bank is required."),
    otherwise: yup.string(),
  }),
  financeBankNum: yup
    .string()
    .nullable(false)
    .test(
      "financeBankNumRequired",
      "Last 4 of SSN/Account number is required.",
      /** Return true if valid, otherwise false... */
      function financeBankNumRequired(financeBankNum = "") {
        const values = this.parent;
        const bank = ("" + values.financeBank).toLowerCase().trim();
        // console.log("financeBankNumRequired", { bank, financeBankNum });
        if (bankReqs[bank]?.financeBankNum) {
          financeBankNum = ("" + financeBankNum).trim();
          // Chase bank
          if (bank === "chase bank" && financeBankNum.length < 8) {
            // https://www.google.com/search?q=how+long+are+chase+bank+account+numbers&oq=how+long+are+chase+bank+account+numbers
            return this.createError({
              path: "financeBankNum",
              message: "Full SSN/Account number is required.",
            });
          }
          // Return true if valid, false to present the default error message.
          return financeBankNum.length > 3;
        }
        // Valid. No bank num required.
        return true;
      },
    ),
  maturityDate: yup
    .date()
    .nullable()
    .when("financeBank", {
      is: value => bankReqs[("" + value).toLowerCase()]?.maturityDate,
      then: yup.date().required("Maturity date is required."),
    }),
});

export const VehicleInfoForm = React.memo(
  /**
   *
   */
  function VehicleInfoForm({ isLeaseReturn, sellPageType }) {
    const {
      dealer: dealerId,
      wizard: { forms, vin = "", vehicles /*, colors */ },
      saveForm,
      query,
    } = React.useContext(SellWizardContext);
    const getBanks = useAction(sellActions.getBanks(dealerId));
    const loading = !vin;
    // console.log("VEHICLE INFO", vin, vehicles, forms);

    const vinInputProps = React.useMemo(() => {
      const startPage = isLeaseReturn ? SellPages.returns : SellPages.sell;
      return {
        endAdornment: (
          <InputAdornment position="end">
            <PageLink to={startPage.url({ vin, query })} replace>
              Change VIN
            </PageLink>
          </InputAdornment>
        ),
        readOnly: true,
      };
    }, [query, vin, isLeaseReturn]);

    const defaults = defaultValues[sellPageType];
    /** @type {[typeof defaultValues.sell]} */
    const [initialValues, setInitialValues] = React.useState(defaults);
    const prefill = usePrefill(query.pf);
    React.useEffect(() => {
      setInitialValues({
        ...defaults,
        trim: prefill?.trim ?? vehicles[0]?.trim ?? defaults.trim,
        vehicle: vehicles[0] ?? defaults.vehicle,
        mileage: prefill ? "" + prefill.mileage : defaults.mileage,
        ownerStatusId: prefill?.ownerStatusId ?? defaults.ownerStatusId,
        financeBank: prefill?.financeBank ?? defaults.financeBank,
        financeBankNum: prefill?.financeBankNum ?? defaults.financeBankNum,
        maturityDate: prefill?.maturityDate ?? defaults.maturityDate,
        ...forms["vehicle-info"],
        vin,
      });
    }, [vin, vehicles, forms, defaults, prefill]);

    const frm = useFormik({
      enableReinitialize: true,
      initialValues,
      validationSchema,
      validateOnBlur: false,
      validateOnChange: false,
      async onSubmit(values, helpers) {
        if (process.env.NODE_ENV === "development") {
          console.log("VALID VIN", isValidNorthAmericanVin(values.vin));
        }
        await saveForm(values);
      },
    });
    const {
      values: { financeBank, trim, vehicle },
      setFieldValue,
    } = frm;
    // Update vehicle when trim changes.
    React.useEffect(() => {
      const vehicle = vehicles.find(it => it.trim === trim);
      if (vehicle) {
        setFieldValue("vehicle", vehicle);
      }
    }, [trim, vehicles, setFieldValue]);

    const { bankReq, fullBankNum } = React.useMemo(() => {
      const bank = financeBank.toLowerCase().trim();
      return {
        bankReq: bankReqs[bank] ?? {},
        fullBankNum: bank === "chase bank",
      };
    }, [financeBank]);

    return (
      <Form form={frm} promptOnExit={false}>
        <Field
          name="vin"
          label="Vehicle Identification Number (VIN)"
          fullWidth
          InputProps={vinInputProps}
        />
        <Box
          color="#141932"
          fontSize="18px"
          fontWeight="600"
          lineHeight="27px"
          paddingTop="24px"
        >
          {vehicle.year} {vehicle.make} {vehicle.model}
        </Box>
        <Box paddingTop="32px">
          <SelectField
            name="trim"
            label="Trim"
            items={vehicles}
            fullWidth
            itemKey="trim"
            itemText="trim"
            required
            disabled={loading}
          />
        </Box>
        <Box paddingTop="32px">
          <NumberField
            name="mileage"
            label="Exact mileage"
            autoFocus
            fullWidth
            required
            disabled={loading}
          />
        </Box>
        {isLeaseReturn ? (
          <Box
            paddingTop="32px"
            color="#141932"
            fontSize="14px"
            fontWeight="600"
            lineHeight="21px"
          >
            Lease details
          </Box>
        ) : (
          <Box paddingTop="32px">
            <ToggleButtonsField
              label="Who owns the car?"
              name="ownerStatusId"
              fullWidth
              items={ownerStatusItems}
              required
            />
          </Box>
        )}
        {(frm.values.ownerStatusId === 2 || frm.values.ownerStatusId === 3) && (
          <>
            <Box paddingTop="16px">
              <CheckboxField
                name="financeBankUnknown"
                label="I don't know which bank finances the car."
              />
            </Box>
            {!frm.values.financeBankUnknown && (
              <Box paddingTop="16px">
                {/* <Field name="financeBank" label="Bank name" fullWidth required /> */}
                <AutocompleteField
                  id="finance-bank"
                  getOptions={getBanks}
                  label="Bank name"
                  name="financeBank"
                  optionsValueKey="name"
                  freeSolo
                  fullWidth
                  // autoSelect={false}
                  // loadingText={null}
                  TextFieldProps={requiredProps}
                />
              </Box>
            )}
            {bankReq.financeBankNum && (
              <Box paddingTop="16px">
                <Field
                  name="financeBankNum"
                  label={
                    fullBankNum
                      ? "Full SSN or Acct #"
                      : "Full Acct # or Last 4 of SSN"
                  }
                  fullWidth
                  disabled={loading}
                  required
                />
              </Box>
            )}
            {bankReq.maturityDate && (
              <Box paddingTop="16px">
                <DateField
                  name="maturityDate"
                  label="Maturity Date"
                  fullWidth
                  disabled={loading}
                  required
                />
              </Box>
            )}
          </>
        )}
        {/* <AutoSaveWizardForm name="vehicle-info" /> */}
        <WizardFormActions disabled={loading} isFirstSection />
      </Form>
    );
  },
);

const requiredProps = {
  required: true,
};

/* Content from above "Lease details":
        <Box paddingTop="10px">
          <SelectField
            name="color"
            label="Exterior color (outside of car)"
            items={colorItems}
            fullWidth
            required
            disabled={loading}
          />
        </Box>
        <Box paddingTop="10px">
          <ToggleButtonsField
            name="hasKeys"
            label="How many keys do you have for this vehicle?"
            items={hasKeysItems}
            required
            disabled={loading}
          />
        </Box>
        <Box paddingTop="32px">
          <Box
            color="#141932"
            fontSize="14px"
            fontWeight="600"
            lineHeight="21px"
            paddingBottom="16px"
          >
            Has your vehicle been in an accident?
          </Box>
          <ToggleButtonsField
            // label="Has your vehicle been in an accident?"
            name="accident"
            fullWidth
            yesNo
            required
            disabled={loading}
          />
        </Box>
        <Box paddingTop="24px">
          <Box
            color="#141932"
            fontSize="14px"
            fontWeight="600"
            lineHeight="21px"
            paddingBottom="16px"
          >
            Select all features that apply
          </Box>
          <CheckboxArrayField
            name="featureIds"
            // label="Features"
            items={featureItems}
            disabled={loading}
            vertical
          />
        </Box>
        <Box paddingTop="32px">
          <Field
            name="packageNotes"
            label="Any other features?"
            fullWidth
            multiline
            rows={2}
            rowsMax={4}
            disabled={loading}
          />
        </Box> */
