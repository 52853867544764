import React from "react";
import {
  Dialog,
  DialogTitle,
  IconButton,
  Typography,
  makeStyles,
} from "@material-ui/core";
// Icons
import CloseIcon from "@material-ui/icons/Close";
// Local

const useStyles = makeStyles(
  theme => ({
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    title: {
      paddingBottom: 0,
    },
    titleText: { textAlign: "center" },
    titleIcon: { marginRight: 6, marginTop: 4 },
  }),
  {
    classNamePrefix: "SimpleDialog",
  },
);

export const SimpleDialog = React.memo(
  /**
   * @typedef {import("@material-ui/core").DialogProps} DialogProps
   *
   * @typedef {object} SimpleDialogProps
   * @property {React.Children} children
   * @property {React.ComponentType} [icon]
   * @property {string} [name] Name of the dialog for creating an HTML id.
   * @property {boolean} open True if dialog should be showing.
   * @property {import("@material-ui/core").ModalProps['onClose']} onClose
   * @property {React.ReactNode} title Visual title text or component.
   *
   * @param {SimpleDialogProps & DialogProps} props
   */
  function SimpleDialog(props) {
    const {
      //
      children,
      icon: DialogIcon,
      name = `sdlg-${Date.now()}`,
      open,
      onClose,
      title = <>&nbsp;</>,
      ...dialogProps
    } = props;
    const classes = useStyles();

    const titleId = `${name}-title`;
    // CONSIDER: Wrap the children of SimpleDialog with some common element(s).
    return (
      <Dialog
        onClose={onClose}
        open={open}
        aria-labelledby={titleId}
        {...dialogProps}
      >
        <DialogTitle disableTypography id={titleId} className={classes.title}>
          <Typography variant="h5" className={classes.titleText}>
            {DialogIcon ? <DialogIcon className={classes.titleIcon} /> : null}
            {title}
          </Typography>
          {onClose ? (
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </DialogTitle>
        {children}
      </Dialog>
    );
  },
);
