import React from "react";
import { Box, Typography } from "@material-ui/core";

export const NotFoundPage = React.memo(
  /**
   *
   */
  function NotFoundPage() {
    return (
      <Box padding="80px">
        <Typography variant="h1" align="center">
          Page Not Found
        </Typography>
      </Box>
    );
  },
);
