import React from "react";
import {
  //
  Box,
  // Container,
  // Paper,
  // Typography,
  // makeStyles,
} from "@material-ui/core";
// Local
// import { isMobileAgent } from "../../config";
import { Navigation, inIframe, openNewTabForAsync } from "../../lib";
import {
  SellWizardSections,
  sellActions,
  sellSelectors,
  useAction,
  useSelector,
} from "../../state";
// import { mainToolbarHeight } from "../../layouts/main/MainToolbar.styles";
import { SellWizardContextProvider } from "./context";
import { SellPages } from "./index";
// import { SellWizardSection } from "./components/SellWizardSection";
import { VehicleInfoForm } from "./forms/VehicleInfoForm";
import { ContactForm } from "./forms/ContactForm";
import { SellPageLayout } from "./SellPageLayout";
import { withSellPageType } from "./hocs";

const text = {
  returns: {
    title: "",
  },
  sell: {
    title: "Appraisal",
  },
};

const wizardSectionById = {
  returns: {
    "vehicle-info": {
      step: "1",
      title: "Lease return",
      view: VehicleInfoForm,
    },
    contact: {
      step: "2",
      title: "Your information",
      view: ContactForm,
    },
  },
  sell: {
    "vehicle-info": {
      step: "1",
      title: "Vehicle information",
      view: VehicleInfoForm,
    },
    contact: {
      step: "2",
      title: "Your information",
      view: ContactForm,
    },
  },
};

// const useStyles = makeStyles(
//   theme => ({
//     // root: {
//     //   // margin: 0,
//     // },
//     actions: {
//       borderTop: "2px solid #ccc",
//       justifyContent: "center",
//       padding: 32,
//     },
//   }),
//   {
//     classNamePrefix: "SellWizardPage",
//   },
// );

export const SellWizardPage = React.memo(
  withSellPageType(
    /**
     *
     */
    function SellWizardPage({
      isLeaseReturn,
      pageParams: { vin },
      pageQuery: query,
      pageQuery: {
        ao: autoOffer,
        dealer = process.env.REACT_APP_DEFAULT_DEALER_ID,
        phone,
      },
      sellPageType,
    }) {
      // const classes = useStyles();

      // const dealerId = Number(dealer);

      const getVinDetails = useAction(sellActions.getVinDetails);
      const saveWizardForm = useAction(sellActions.saveWizardForm);
      const submitWizard = useAction(sellActions.submitWizard);
      const uploadDocument = useAction(sellActions.uploadDocument);

      const wizard = useSelector(sellSelectors.all);
      // console.log("WIZARD", wizard);

      const [busy, setBusy] = React.useState(false);
      const [section, setSection] = React.useState(SellWizardSections[0]);
      const sectionInfo = wizardSectionById[sellPageType][section];
      // const setExpanded = React.useCallback(nextSection => {
      //   setSection(nextSection);
      //   scrollToSection(nextSection);
      // }, []);
      // const [saved, setSaved] = React.useState(false);

      // const onExpanded = React.useCallback(
      //   (nextSection, target) => {
      //     if (!nextSection) {
      //       return;
      //     }
      //     // setExpanded(nextSection);
      //     setSection(nextSection);
      //   },
      //   // [setExpanded],
      //   [],
      // );

      const { upload, saveForm, submit } = React.useMemo(
        () => ({
          saveForm: async data => {
            // setSaved(true); // We only have one form before the last so....
            return saveWizardForm(dealer, vin, section, data);
          },
          submit: async submitOptions => {
            const iframe = inIframe();
            const newTab =
              iframe && !autoOffer ? openNewTabForAsync() : undefined;
            const data = await submitWizard({
              vin,
              appraisalTypeId: isLeaseReturn ? 2 : 1,
              query,
            });
            if (!autoOffer && data.inspectionUrl && !submitOptions?.later) {
              if (newTab) {
                newTab.setUrlAndFocus(data.inspectionUrl);
              } else if (!iframe) {
                window.location.replace(data.inspectionUrl);
                return;
              }
            }
            const completedPage = isLeaseReturn
              ? SellPages.returnsWizardCompleted
              : SellPages.sellWizardCompleted;
            Navigation.replace(completedPage, {
              query: {
                ...query,
                inspectionUrl: data.inspectionUrl,
                ...data.offerVars, // offer=$24,850&max=$25,840
              },
              params: { vin },
            });
            requestAnimationFrame(function () {
              window.scrollTo(0, 0);
            });
          },
          upload: async (typeName, file) => {
            return uploadDocument(dealer, vin, typeName, file);
          },
        }),
        [
          autoOffer,
          query,
          vin,
          dealer,
          section,
          isLeaseReturn,
          uploadDocument,
          saveWizardForm,
          submitWizard,
        ],
      );

      const nextSection = React.useCallback(
        async submitOptions => {
          const idx = SellWizardSections.indexOf(section);
          // console.log("CONTINUE FROM", idx);
          if (idx === SellWizardSections.length - 1) {
            await submit(submitOptions);
            return;
          }
          const nextIdx = idx + 1;
          // console.log("CONTINUE TO", nextIdx, SellWizardSections[nextIdx]);
          setSection(SellWizardSections[nextIdx]);
          requestAnimationFrame(() => {
            window.scrollTo(0, 0);
          });
        },
        [section, submit],
      );

      const prevSection = React.useCallback(() => {
        const idx = SellWizardSections.indexOf(section);
        // console.log("CONTINUE FROM", idx);
        if (idx === 0) {
          return;
        }
        const prevIdx = idx - 1;
        // console.log("CONTINUE TO", nextIdx, SellWizardSections[nextIdx]);
        setSection(SellWizardSections[prevIdx]);
      }, [section]);

      React.useEffect(() => {
        getVinDetails(dealer, vin);
      }, [dealer, vin, getVinDetails]);

      return (
        <SellPageLayout dealer={dealer}>
          <SellWizardContextProvider
            busy={busy}
            dealer={dealer}
            wizard={wizard}
            nextSection={nextSection}
            prevSection={prevSection}
            query={query}
            saveForm={saveForm}
            setBusy={setBusy}
            // submit={submit}
            upload={upload}
          >
            <Box
              color="#141932"
              fontSize="40px"
              fontWeight="600"
              lineHeight="60px"
              paddingTop="24px"
            >
              {text[sellPageType].title}
            </Box>
            <Box
              color="#141932"
              fontSize="20px"
              lineHeight="30px"
              paddingTop="8px"
              paddingBottom="48px"
            >
              Step {sectionInfo.step}: {sectionInfo.title}
            </Box>
            {section === "vehicle-info" ? (
              <VehicleInfoForm
                isLeaseReturn={isLeaseReturn}
                sellPageType={sellPageType}
              />
            ) : (
              <ContactForm
                defaultPhone={phone}
                isLeaseReturn={isLeaseReturn}
                sellPageType={sellPageType}
              />
            )}
          </SellWizardContextProvider>
        </SellPageLayout>
      );
    },
  ),
);

// function scrollToSection(name) {
//   if (!isMobileAgent) {
//     return;
//   }
//   requestAnimationFrame(() => {
//     const el = document.getElementById(name);
//     if (el) {
//       // const yOffset = -mainToolbarHeight;
//       const y = 225;
//       // const y = el.getBoundingClientRect().top +
//       //   // window.pageYOffset +
//       //   yOffset;
//       // console.log("SCROLLING", y);
//       window.scrollTo({ top: y, behavior: "smooth" });
//     }
//   });
// }

// function renderWizardSections(section, onExpanded, saved) {
//   return (
//     <>
//       <SellWizardSection
//         id="vehicle-info"
//         expanded={section === "vehicle-info"}
//         title="Vehicle Information"
//         onExpanded={onExpanded}
//       >
//         <VehicleInfoForm />
//       </SellWizardSection>
//       <SellWizardSection
//         id="contact"
//         expanded={section === "contact"}
//         title="Your Information"
//         onExpanded={onExpanded}
//         disabled={!saved}
//       >
//         <ContactForm />
//       </SellWizardSection>
//     </>
//   );
// }
