import React from "react";
import { Button } from "@material-ui/core";
// Local
import { RouterLink } from "../../lib";

export const PageLinkButton = React.memo(
  /**
   * @typedef {import("@material-ui/core").ButtonProps} ButtonProps
   * @typedef {import("react-router-dom").LinkProps} RouterLinkProps
   * @typedef {object} PageLinkButtonProps
   * @property {PageDefinition} [page]
   *
   * @param {ButtonProps & RouterLinkProps & PageLinkButtonProps} param0
   */
  function PageLinkButton({ children, page, style, to, ...rest }) {
    if (page) {
      to = page.path;
    }
    return (
      <Button component={RouterLink} to={to} {...rest}>
        {children}
      </Button>
    );
  },
);
