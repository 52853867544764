// import { Navigation } from "../../lib";
import { HomePage } from "./HomePage";
import { NotFoundPage } from "./NotFoundPage";

export const MainPages = {
  home: {
    anon: true,
    path: "/",
    pathExact: true,
    title: "Turnyn",
    view: HomePage,
    isDefault: true,
  },
  notFound: {
    anon: true,
    path: "*",
    title: "Not Found",
    view: NotFoundPage,
  },
};
