import { makeStyles } from "@material-ui/core";

// const animationGif = "https://i.giphy.com/ZCIFBKx72FWIbvdqFl.gif";

// const purple = "#4E50FF";

export const useStyles = makeStyles(
  theme => ({
    root: {
      // paddingBottom: 400,
    },
    content: {
      paddingLeft: 120,
      paddingRight: 120,
      [theme.breakpoints.down("sm")]: {
        paddingLeft: 12,
        paddingRight: 12,
      },
    },
    intro: {
      paddingTop: 80,
      paddingBottom: 69,
    },
    sellBtn: {
      position: "relative",
      marginBottom: -60,
      left: 0,
      zIndex: 20,
      padding: "18px 24px",
      [theme.breakpoints.down("sm")]: {
        padding: "12px 16px",
        left: 12,
        marginBottom: 0,
      },
      "& .MuiButton-label": {
        fontSize: "20px",
        lineHeight: "30px",
      },
    },
    vframe: {
      // HACK: Match video height to remove space left by relative positioning.
      height: 463,
      [theme.breakpoints.down("sm")]: {
        height: "unset",
      },
    },
    video: {
      display: "block",
      position: "relative",
      margin: "0 auto",
      zIndex: 0,

      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    vshadow: {
      position: "relative",
      margin: "0 auto",
      zIndex: 1,
      borderRadius: 2,
      boxShadow: "inset 0 0 24px 16px white",
      // HACK: Match video height...
      top: -463,
      width: 1280,
      height: 463,
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    /** Testimonial box */
    tbox: {
      marginTop: 32,
      background:
        "repeating-linear-gradient(#F9FAFC, #F9FAFC 80%, #FFF 80%, #FFF 100%)",
    },
    "@keyframes bounce": {
      "0%": { transform: "translateY(0)" },
      "100%": { transform: "translateY(-20px)" },
    },
    tarrow: {
      marginTop: -12,
      animation: "$bounce 0.8s",
      animationDirection: "alternate",
      animationIterationCount: "infinite",
    },
    /** NOTE: This is used for multiple sections! */
    tlayout: {
      display: "flex",
      justifyContent: "space-around",
      [theme.breakpoints.down("md")]: {
        flexDirection: "column-reverse",
        alignItems: "center",
      },
    },
    /** How it works box */
    hiwbox: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
      flex: 1,
      maxWidth: 1024,
      padding: "80px 160px 45px 160px",
      [theme.breakpoints.down("md")]: {
        padding: "80px 0 45px 0",
        // HACK: Hide the first column, icons...
        // marginLeft: -200,
      },
    },
    mcenter: {
      [theme.breakpoints.down("md")]: {
        textAlign: "center",
      },
    },
    // icons: {
    //   [theme.breakpoints.down("sm")]: {
    //     marginLeft: -
    //   }
    // }
  }),
  {
    classNamePrefix: "HomePage",
  },
);
