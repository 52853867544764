import React from "react";

export const StepsImage = React.memo(
  /**
   * @param {React.SVGProps<SVGSVGElement>} param0
   */
  function StepsImage({
    height = "404px",
    width = "68px",
    viewBox = "0 0 68 404",
    fill = "#4E50FF",
    ...props
  }) {
    return (
      <svg width={width} height={height} viewBox={viewBox} {...props}>
        <title>Steps</title>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-442.000000, -1399.000000)">
            <g transform="translate(0.000000, 1256.000000)">
              <g transform="translate(448.000000, 149.000000)">
                <path
                  d="M11.2544968,5 C20.1403386,27.9950894 42.1949262,53.6516273 43.593096,80.6483144 C44.3562225,95.3832116 46.0371472,110.457945 44.1020151,124.969336 C37.4443583,174.894538 8.46924472,214.507393 11.1781742,267.085211 C13.0033209,302.509622 32.6175588,330.896057 44.7634776,360"
                  stroke="#DADFF6"
                  strokeWidth="2"
                  strokeDasharray="8,8"
                ></path>
                <g>
                  <circle
                    stroke="#FFFFFF"
                    strokeWidth="12"
                    fill="#4E50FF"
                    cx="28"
                    cy="28"
                    r="28"
                  ></circle>
                  <text
                    fontFamily="Poppins-SemiBold, Poppins"
                    fontSize="24"
                    fontWeight="500"
                    fill="#FFFFFF"
                  >
                    <tspan x="24" y="37">
                      1
                    </tspan>
                  </text>
                </g>
                <g transform="translate(0.000000, 168.000000)">
                  <circle
                    stroke="#FFFFFF"
                    strokeWidth="12"
                    fill="#4E50FF"
                    cx="28"
                    cy="28"
                    r="28"
                  ></circle>
                  <text
                    fontFamily="Poppins-SemiBold, Poppins"
                    fontSize="24"
                    fontWeight="500"
                    fill="#FFFFFF"
                  >
                    <tspan x="21" y="37">
                      2
                    </tspan>
                  </text>
                </g>
                <g transform="translate(0.000000, 336.000000)">
                  <circle
                    stroke="#FFFFFF"
                    strokeWidth="12"
                    fill="#4E50FF"
                    cx="28"
                    cy="28"
                    r="28"
                  ></circle>
                  <text
                    fontFamily="Poppins-SemiBold, Poppins"
                    fontSize="24"
                    fontWeight="500"
                    fill="#FFFFFF"
                  >
                    <tspan x="21" y="37">
                      3
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  },
);
