import React from "react";
// Local
import { uiActions, uiSelectors, useAction, useSelector } from "../../state";
import { SimpleDialog } from "./SimpleDialog";

/**
 * @param {string} dialogViewName
 */
function createHandlers(dialogViewName) {
  return {
    getView: uiSelectors.forView(dialogViewName),
    open: uiActions.toShowView(dialogViewName),
    close: uiActions.toRemoveView(dialogViewName),
  };
}
/**
 * @typedef {import("@material-ui/core").DialogProps} DialogProps
 * @typedef {import("./SimpleDialog").SimpleDialogProps} SimpleDialogProps
 *
 * @type {React.NamedExoticComponent<SimpleDialogProps & DialogProps> &
 * { createHandlers:typeof createHandlers }}
 */
export const SimpleDialogView = React.memo(
  /**
   * @param {SimpleDialogProps & DialogProps} props
   */
  function SimpleDialogView(props) {
    const { handlers } = props;
    const view = useSelector(handlers.getView);
    const onClose = useAction(handlers.close);
    const onCloseDialog = React.useCallback(
      (e, reason) => {
        // CONSIDER: Do something if reason is "backdropClick"/"escapeKeyDown"...
        onClose();
      },
      [onClose],
    );

    if (!view) {
      return null;
    }

    return <SimpleDialog onClose={onCloseDialog} open={!!view} {...props} />;
  },
);

SimpleDialogView.createHandlers = createHandlers;
