import React from "react";
import { MenuItem } from "@material-ui/core";
// Local
import { Field } from "./Field";

const defaultEmptyText = <>&nbsp;</>;

export const SelectField = React.memo(
  /**
   * @typedef {import("./Field").FieldProps} FieldProps
   * @typedef {import("./Field").TextFieldProps} TextFieldProps
   *
   * @typedef {object} SelectFieldProps
   * @property {boolean} [disableUnderline]
   * @property {boolean} [emptyEnabled] `true` to allow picking a the empty item.
   * @property {string | boolean} [emptyText] `<>&nbsp;</>` if `emptyEnabled` else
   * `undefined`. Or set `true` to display default empty text of `<>&nbsp;</>`.
   * @property {string} [emptyValue] `""`
   * @property {string} [itemKey] Item key property. `"id"`
   * @property {string} [itemText] Item text property. `"title"`
   * @property {string} [itemValue] Item value property. `"id"`
   * @property {MenuItemProps} [itemProps] Menu item props to pass to each item.
   * @property {{id:string|number,title:string,value?:string|number}[]} [items]
   * The items list to derive menu items from.
   *
   * @param {SelectFieldProps & FieldProps & TextFieldProps} props
   */
  function SelectField(props) {
    let {
      children,
      // disableUnderline,
      emptyEnabled,
      emptyText = emptyEnabled ? defaultEmptyText : undefined,
      emptyValue = "",
      itemKey = "id",
      itemText = "title",
      itemValue = itemKey,
      itemProps,
      items = [],
      value = emptyValue,

      InputLabelProps,
      // InputProps,
      SelectProps,

      ...fieldProps
    } = props;
    const displayEmpty = emptyText !== undefined;
    if (emptyText === true) {
      emptyText = defaultEmptyText;
    }
    return (
      <Field
        allowClear={false}
        select
        value={value}
        // InputProps={{
        //   disableUnderline,
        //   ...InputProps,
        // }}
        InputLabelProps={{
          shrink: true,
          ...InputLabelProps,
        }}
        SelectProps={{
          displayEmpty,
          defaultValue: "",
          ...SelectProps,
        }}
        {...fieldProps}
      >
        {displayEmpty && (
          <MenuItem {...itemProps} disabled={!emptyEnabled} value={emptyValue}>
            {emptyText}
          </MenuItem>
        )}
        {children}
        {items.map(it => (
          <MenuItem {...itemProps} key={it[itemKey]} value={it[itemValue]}>
            {it[itemText]}
          </MenuItem>
        ))}
      </Field>
    );
  },
);
