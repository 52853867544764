import React from "react";

export const DownIcon = React.memo(
  /**
   * @param {React.SVGProps<SVGSVGElement>} param0
   */
  function DownIcon({
    height = "24px",
    width = "40px",
    viewBox = "0 0 40 24",
    fill = "#4E50FF",
    title = "Scroll down",
    ...props
  }) {
    return (
      <svg width={width} height={height} viewBox={viewBox} {...props}>
        <title>{title}</title>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g
            transform="translate(-120.000000, -812.000000)"
            fill={fill}
            fillRule="nonzero"
          >
            <polygon
              transform="translate(140.000000, 824.000000) rotate(-180.000000) translate(-140.000000, -824.000000) "
              points="160 836 150.118577 836 131.442688 836 120 836 139.980237 812"
            ></polygon>
          </g>
        </g>
      </svg>
    );
  },
);
