import { createMuiTheme, useMediaQuery } from "@material-ui/core";

/** Material-ui default theme, to copy from where necessary. */
const defaults = createMuiTheme();

// #region Example to copy shadows from default theme.
/**
 * Override box shadows[3]. Must provide all 25 array elements...
 * - https://github.com/mui-org/material-ui/issues/8780
 * - https://gist.github.com/phacks/6c3c3a5f395f6e9660ae132c237250a3
 */
// const boxShadow = "0 0 4px -4px rgba(0,0,0,0.12), 0 0 8px 0 rgba(0,0,0,0.16)";
const boxShadow = "0 2px 24px 0 #DADFF6, 0 2px 40px 0 #F9FAFC";
const shadows = [...defaults.shadows];
shadows[24] = boxShadow;
// #endregion

/**
 * Default theme for the app based on the
 * [Materiaul UI default theme](https://material-ui.com/customization/default-theme/)
 * with example overrides.
 */
export const defaultTheme = createMuiTheme({
  appLayout: {
    topGutterHeight: 24,
  },
  // mixins: {
  // Example of custom mixin. Use like `myClass: theme.mixins.flexCol,`
  // flexCol: {
  //   display: "flex",
  //   flexDirection: "column",
  // },
  // },
  palette: {
    type: "light",
    background: { default: "#ffffff" },
    // primary: { main: "#0d65bf" },
    // secondary: { main: "#2196f3" },
    primary: { main: "#4e50ff" },
    secondary: { main: "#fea30b" },
  },
  overrides: {
    MuiAccordion: {
      root: {
        // overflowAnchor: undefined,
        marginBottom: 1, // Always show the bottom border.
        "&.Mui-expanded:before": {
          opacity: "1 !important", // Always show the top border.
        },
        "&$expanded": {
          margin: 0, // Stop adding margin when expanded.
          marginBottom: 1, // Always show the bottom border.
        },
      },
    },
    // MuiAccordionDetails: {
    //   root: {
    //     // Add top padding to details since it was taken from expanded summary.
    //     // padding: "20px 16px 16px",
    //     // (Disabled additional top padding for now, for more dense layout.)
    //   },
    // },
    MuiAccordionSummary: {
      root: {
        backgroundColor: "#F0F0F0", // Make headers stand out more.
        "&$expanded": {
          minHeight: 48, // Stop adding height when expanded.
        },
      },
      content: {
        "&$expanded": {
          margin: 0, // Stop adding margin when expanded.
        },
      },
    },
    MuiButton: {
      root: {
        textTransform: "none",
        userSelect: "none",
      },
    },
    // MuiOutlinedInput: {
    //   root: {
    //     borderRadius: 8,
    //   },
    // },
    MuiFormControlLabel: {
      root: {
        // Don't let user select form label text. This helps particularly with
        // checkboxes, where the user clicks the label to toggle the checkbox.
        userSelect: "none",
      },
    },
    MuiFormLabel: {
      asterisk: {
        color: "#db3131",
      },
    },
    MuiInputLabel: {
      root: {
        userSelect: "none", // Same as MuiFormControlLabel...
      },
    },
    MuiTab: {
      root: {
        minWidth: 0,
        [defaults.breakpoints.up("sm")]: {
          minWidth: 0,
        },
      },
    },
  },
  props: {
    MuiFormHelperText: {
      // Use a <div> instead of a <p> as the base for this component so we can
      // avoid React "validateDOMnesting" errors if we try to put a <div> inside
      // of a <p>...
      component: "div",
    },
    MuiTextField: {
      variant: "outlined",
      // onFocus: event => {
      //   event.target.select();
      // },
    },
  },
  // #region Example global overrides for this theme:
  // overrides: {

  //   // Example to override "contained" variant of the Material-ui Button:
  //   // MuiButton: {
  //   //   contained: {
  //   //     boxShadow: "none",
  //   //     "&:hover": {
  //   //       boxShadow: "none",
  //   //     },
  //   //   },
  //   // },

  //   // Example to override the "contained" variant of Material-ui ButtonGroup:
  //   // MuiButtonGroup: {
  //   //   contained: {
  //   //     boxShadow: "none",
  //   //     border: "1px solid rgba(0,0,0,0.16)",
  //   //   },
  //   // },

  //   // Example to override the paper style of the Material-ui Menu:
  //   // MuiMenu: {
  //   //   paper: {
  //   //     boxShadow,
  //   //   },
  //   // },

  // },
  // #endregion

  // #region Example default props for any Material-ui components rendered with
  // this theme:
  // props: {
  //   MuiCard: {
  //     variant: "outlined",
  //   },
  // },
  // #endregion

  // Example to set custom shadows:
  shadows,
  typography: {
    fontFamily: ["Poppins", `"Helvetica"`, `"Arial"`, `sans-serif`],
    h1: { fontWeight: 600 },
    h2: { fontWeight: 600 },
    h3: { fontWeight: 600 },
    body1: { fontWeight: 400 },
    body2: { fontWeight: 400 },
  },
});

/**
 * Returns `true` if the screen is sized for mobile. Depends on theme
 * breakpoints, otherwise this function would be defined in `lib/hooks.js`
 */
export function useMobile(size = "xs") {
  return useMediaQuery(defaultTheme.breakpoints.down(size));
}
