import React from "react";
// Local
import { usePrefill } from "../../state";
import { SellPageLayout } from "./SellPageLayout";
import { SellStartForm } from "./forms/SellStartForm";
import { withSellPageType } from "./hocs";

export const SellStartPage = React.memo(
  withSellPageType(
    /**
     *
     */
    function SellStartPage({
      isLeaseReturn,
      pageQuery: {
        dealer = process.env.REACT_APP_DEFAULT_DEALER_ID,
        pf: prefillUid,
      },
      sellPageType,
    }) {
      const prefill = usePrefill(prefillUid);

      if (prefillUid && !prefill) {
        return "Loading...";
      }

      return (
        <SellPageLayout dealer={dealer}>
          <SellStartForm
            isLeaseReturn={isLeaseReturn}
            sellPageType={sellPageType}
            prefill={prefill}
          />
        </SellPageLayout>
      );
    },
  ),
);
