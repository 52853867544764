import React, { Suspense } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  InputAdornment,
} from "@material-ui/core";
// Local
import { useMobile } from "../../../themes";
import vinPng from "../../../assets/img/vin.png";

import { Field, SelectStateField } from "../../../components";
import { Form, Navigation, useFormik, useLocation, yup } from "../../../lib";
import { sellActions, useAction } from "../../../state";
import { SellPages } from "../";

const VideoDecode = React.lazy(() => import("../components/VideoDecode"));

const text = {
  returns: {
    title: "Return your lease",
    tagline: "Just fill out the form and hand in your keys.",
    submit: "Start lease return",
  },
  sell: {
    title: "Sell or trade in",
    tagline:
      "Get cash for your car. " +
      "Free inspection and pickup. Quick response times.",
    submit: "Start appraisal",
  },
};

const validationSchema = yup.object(
  {
    plate: yup.lazy(() =>
      yup.string().when("vin", {
        is: value => !value || value.length < 1,
        then: yup
          .string()
          .required("Please enter License Plate + State or VIN."),
        otherwise: yup.string(),
      }),
    ),
    state: yup.lazy(() =>
      yup.string().when("vin", {
        is: value => !value || value.length < 1,
        then: yup
          .string()
          .required("Please enter License Plate + State or VIN."),
        otherwise: yup.string(),
      }),
    ),
    vin: yup.lazy(() =>
      yup.string().when(["plate", "state"], {
        is: (plate, state) =>
          !plate || plate.length < 1 || !state || state.length < 1,
        then: yup
          .string()
          .required("Please enter License Plate + State or VIN.")
          .min(17, "VIN must be at least 17 characters."),
        otherwise: yup.string(),
      }),
    ),
  },
  [["plate", "state"], ["vin"]],
);

export const SellStartForm = React.memo(
  /**
   *
   */

  function SellStartForm({ isLeaseReturn, sellPageType, prefill }) {
    const isMobile = useMobile();

    const [openDialog, setOpenDialog] = React.useState(false);
    const isLandscape = () =>
        window.matchMedia("(orientation:landscape)").matches,
      [orientation, setOrientation] = React.useState(
        isLandscape() ? "landscape" : "portrait",
      ),
      onWindowResize = () => {
        clearTimeout(window.resizeLag);
        window.resizeLag = setTimeout(() => {
          delete window.resizeLag;
          setOrientation(isLandscape() ? "landscape" : "portrait");
        }, 200);
      };

    React.useEffect(
      () => (
        // eslint-disable-next-line no-sequences
        onWindowResize(),
        window.addEventListener("resize", onWindowResize),
        () => window.removeEventListener("resize", onWindowResize)
      ),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [],
    );
    const {
      query: {
        vin: vinFromUrl,
        // plate: plateQuery,
        ...query
      },
    } = useLocation();
    const { dealer = process.env.REACT_APP_DEFAULT_DEALER_ID } = query;
    const [autofocusPlate, autofocusVin, initialValues] = React.useMemo(() => {
      return [
        !vinFromUrl,
        !!vinFromUrl,
        {
          plate: prefill?.plate ?? "",
          state: prefill?.plateState ?? "",
          vin: prefill?.vin ?? (vinFromUrl || ""),
        },
      ];
    }, [prefill, vinFromUrl]);

    const getVinFromPlate = useAction(sellActions.getVinFromPlate);
    const getVinDetails = useAction(sellActions.getVinDetails);

    const frm = useFormik({
      enableReinitialize: true,
      initialValues,
      validationSchema,
      validateOnBlur: false,
      validateOnChange: false,
      async onSubmit(values, helpers) {
        let { vin } = values;
        const errField = vin ? "vin" : "plate";
        try {
          if (!vin) {
            // Errors with 404 if not found...
            const data = await getVinFromPlate(dealer, {
              plate: values.plate,
              state: values.state,
            });
            vin = data.vin;
          } else {
            // Errors with 404 if not found...
            await getVinDetails(dealer, vin);
          }
        } catch (err) {
          let message = "There was an error. Please try again.";
          if (err?.response?.status === 404) {
            message = "Vehicle not found. Please try again.";
          }
          frm.setFieldError(errField, message);
          return;
        }
        if (!vin) {
          frm.setFieldError(errField, "Vehicle not found. Please try again.");
          return;
        }
        const wizardPage = isLeaseReturn
          ? SellPages.returnsWizard
          : SellPages.sellWizard;
        Navigation.go(wizardPage.url({ vin, query }));
        requestAnimationFrame(function () {
          window.scrollTo(0, 0);
        });
      },
    });

    return (
      <div>
        <Box
          color="#141932"
          fontSize="40px"
          fontWeight="600"
          lineHeight="60px"
          paddingTop="24px"
        >
          {text[sellPageType].title}
        </Box>
        <Box color="#141932" fontSize="20px" lineHeight="30px" paddingTop="8px">
          {text[sellPageType].tagline}
        </Box>
        <Form form={frm} promptOnExit={false}>
          <Box paddingTop="48px">
            <Field
              name="plate"
              label="License plate"
              autoFocus={autofocusPlate}
              fullWidth
              disabled={frm.isSubmitting}
            />
          </Box>
          <Box paddingTop="32px">
            <SelectStateField
              name="state"
              label="State"
              fullWidth
              disabled={frm.isSubmitting}
            />
          </Box>
          <Box
            color="#141932"
            fontSize="18px"
            fontWeight="600"
            lineHeight="27px"
            paddingTop="36px"
          >
            Or enter VIN
          </Box>

          <Box paddingTop="16px"></Box>

          <Box paddingTop="16px">
            <Field
              name="vin"
              label="VIN number"
              autoFocus={autofocusVin}
              fullWidth
              disabled={frm.isSubmitting}
              InputProps={{
                endAdornment: (
                  <>
                    {isMobile && (
                      <InputAdornment position="end" sx={{ m: -2, p: -2 }}>
                        <Button
                          variant="outlined"
                          color="primary"
                          tabIndex="-1"
                          title="Scan with camera to get VIN and Trim."
                          onClick={() => {
                            setOpenDialog(true);
                          }}
                        >
                          SCAN
                        </Button>
                      </InputAdornment>
                    )}
                  </>
                ),
              }}
            />
          </Box>
          <Box paddingTop="40px">
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={frm.isSubmitting}
            >
              <Box padding="10px">{text[sellPageType].submit}</Box>
            </Button>
          </Box>

          <Dialog
            onClose={() => setOpenDialog(false)}
            aria-labelledby="simple-dialog-title"
            open={openDialog}
            fullWidth={true}
          >
            <DialogContent>
              <Suspense fallback={<div>Opening camera...</div>}>
                {orientation === "portrait" && (
                  <>
                    <DialogContentText>
                      Scan your VIN barcode or QR code. Usually located in the
                      drivers side door.
                    </DialogContentText>
                    <img src={vinPng} alt="" style={{ minWidth: "100%" }} />
                  </>
                )}

                <VideoDecode setOpenDialog={setOpenDialog} frm={frm} />
              </Suspense>
            </DialogContent>
          </Dialog>
        </Form>
      </div>
    );
  },
);
