import { makeStyles } from "@material-ui/core";
// Local
// import bgImage from "../../assets/img/AdobeStock_180355076_gradient2_2kx1681_opt.jpeg";
// import { mainToolbarHeight } from "./MainToolbar.styles";

export const useStyles = makeStyles(
  theme => ({
    // root: {
    //   display: "flex",
    //   backgroundColor: "#1f2a40",
    //   backgroundImage: `url(${bgImage})`,
    //   backgroundRepeat: "no-repeat",
    //   backgroundSize: "100%",
    //   backgroundPosition: "center 0",
    //   // height: "100%",
    //   // width: "100%",
    //   [theme.breakpoints.down("md")]: {
    //     backgroundSize: "cover",
    //   },
    // },
    // content: {
    //   // backgroundColor: "#f5f5f5",
    //   // backgroundColor: "#1f2a40",
    //   // backgroundImage: `url(${bgImage})`,
    //   // backgroundRepeat: "no-repeat",
    //   // backgroundSize: "100%",
    //   // backgroundRepeat: "cover",
    //   // backgroundRepeat: "no-repeat",
    //   // backgroundPosition: "top center",
    //   // backgroundSize: "100%",

    //   display: "flex",
    //   flex: 1,
    //   flexDirection: "column",
    //   paddingTop: mainToolbarHeight + 20,
    //   paddingBottom: 1024,
    // },
    backdrop: {
      // backgroundColor: "transparent",
      zIndex: theme.zIndex.modal + 100,
    },
  }),
  {
    classNamePrefix: "MainLayout",
  },
);
