import React from "react";

export const CheckEmailIcon = React.memo(
  /**
   * @param {React.SVGProps<SVGSVGElement>} param0
   */
  function CheckEmailIcon({
    height = "182px",
    width = "202px",
    viewBox = "0 0 202 182",
    fill = "#4E50FF",
    ...props
  }) {
    return (
      <svg width={width} height={height} viewBox={viewBox} {...props}>
        <title>Check your email</title>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-635.000000, -251.000000)">
            <g transform="translate(604.000000, 240.000000)">
              <g transform="translate(31.973109, 12.018145)">
                <path
                  d="M198.272013,68.8288036 C198.20038,68.8288036 198.13046,68.8067469 198.071705,68.7656729 L101.020468,1.01729403 C100.415572,0.597016215 99.6136543,0.598387106 99.0101912,1.02073063 L2.65802049,68.764987 C2.49931903,68.876562 2.28042911,68.8380555 2.16911617,68.6789805 C2.05780323,68.5199054 2.09621922,68.3005002 2.25492069,68.1889252 L98.6071721,0.444668882 C99.4520782,-0.146521163 100.574745,-0.148370488 101.421586,0.440032811 L198.472819,68.1882288 C198.598647,68.2761152 198.652979,68.435712 198.606996,68.5823644 C198.561012,68.7290168 198.425363,68.8288036 198.271999,68.8288036 L198.272013,68.8288036 Z"
                  stroke="#192740"
                  strokeWidth="1.5"
                ></path>
                <polygon
                  fill="#FFFFFF"
                  points="99.9159664 12.5994555 9.99159664 74.335478 9.99159664 168.030367 189.840336 168.030367 189.840336 74.335478"
                ></polygon>
                <path
                  d="M100.413436,103.133187 L50.4703801,82.3615431 L50.4703801,27.3579686 C50.4717716,26.1608671 51.439588,25.1907724 52.633878,25.1893777 L148.445926,25.1893777 C149.640216,25.1907741 150.608031,26.1608678 150.609424,27.3579686 L150.609424,82.4069287 L150.515556,82.4471684 L102.213838,103.123047 C101.924586,103.244879 101.613956,103.307295 101.300205,103.307295 C100.996132,103.307295 100.694916,103.248478 100.413436,103.133187 Z"
                  fill="#FFFFFF"
                  fillRule="nonzero"
                ></path>
                <path
                  d="M101.403162,109.45032 C101.035019,109.45032 100.670325,109.379208 100.329484,109.239749 L43.514692,85.6099477 L43.514692,21.6843023 C43.5163378,20.227988 44.6937307,19.0478234 46.1466248,19.0461737 L154.933179,19.0461737 C156.386074,19.0478234 157.563466,20.227988 157.565112,21.6843023 L157.565112,85.6601003 L102.509395,109.22703 C102.159217,109.374727 101.783079,109.45032 101.403162,109.45032 Z M44.2165267,85.1400337 L100.59712,108.589321 C101.122991,108.80319 101.711947,108.799669 102.235235,108.579528 L156.863281,85.1956806 L156.863281,21.6843023 C156.862018,20.6163617 155.998629,19.7509404 154.933197,19.7496747 L46.1466424,19.7496747 C45.0812098,19.7509404 44.217821,20.6163617 44.2165267,21.6843023 L44.2165267,85.1400337 Z"
                  stroke="#192740"
                  strokeWidth="1.5"
                ></path>
                <path
                  d="M197.57042,68.1252056 L197.500165,68.1252056 L157.214118,85.3680208 L102.09449,108.959925 C101.659504,109.143328 101.169619,109.145859 100.73277,108.96696 L43.8655462,85.3187687 L2.58977974,68.1533457 L2.52672565,68.1252056 L2.45647059,68.1252056 C1.10042308,68.1267138 0,69.2282192 0,70.5874589 L0,177.519602 C0,178.878841 1.10042388,179.980345 2.45647059,179.981855 L197.57042,179.981855 C198.926467,179.980345 200.026891,178.878841 200.026891,177.519602 L200.026891,70.5874589 C200.026891,69.2282192 198.926468,68.1267138 197.57042,68.1252056 L197.57042,68.1252056 Z M199.325042,177.519602 C199.325042,178.490773 198.539311,179.278354 197.57042,179.278354 L2.45647059,179.278354 C1.48758018,179.278354 0.701848739,178.490773 0.701848739,177.519602 L0.701848739,70.5874589 C0.701848739,69.6424407 1.44777494,68.8666116 2.38981601,68.8287066 L43.8655462,86.0785426 L100.462723,109.617684 C101.075375,109.867547 101.761866,109.863752 102.371731,109.607132 L157.214118,86.1313122 L197.640675,68.8287066 C198.58089,68.8694003 199.322877,69.6441551 199.325042,70.5874589 L199.325042,177.519602 Z"
                  stroke="#192740"
                  strokeWidth="1.5"
                ></path>
                <rect
                  fill={fill}
                  x="17.9848739"
                  y="146.220713"
                  width="23.9798319"
                  height="4.00604703"
                  rx="1"
                ></rect>
                <rect
                  fill={fill}
                  x="17.9848739"
                  y="158.238854"
                  width="55.9529412"
                  height="4.00604703"
                  rx="1"
                ></rect>
                <path
                  d="M95.7089679,76.1148936 C94.4645909,76.1172015 93.2532031,75.7148184 92.257538,74.96842 L92.1958141,74.9220767 L79.1979085,64.9705937 C77.5602591,63.7158421 76.7164225,61.6827436 76.9842638,59.637147 C77.2521052,57.5915503 78.5909329,55.8442301 80.4964237,55.0533851 C82.4019144,54.26254 84.5845788,54.5483183 86.2222281,55.80307 L94.6412877,62.2585724 L114.536865,36.3122995 C115.468752,35.0969153 116.845305,34.3015128 118.363677,34.1010841 C119.882048,33.9006555 121.41785,34.3116201 122.6332,35.2435639 L122.509501,35.4115172 L122.636412,35.2460066 C125.163943,37.1885352 125.642053,40.8104112 123.705166,43.3422498 L100.303194,73.8612844 C99.2061482,75.2867428 97.5077139,76.1198688 95.7089679,76.1148936 L95.7089679,76.1148936 Z"
                  fill={fill}
                  fillRule="nonzero"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  },
);
