import React from "react";
import NumberFormat from "react-number-format";
// import { InputAdornment } from "@material-ui/core";
// Local
import { Field } from "./Field";

// TODO: Optionally save value as numeric when calling onChange({ value ... })

function NumberInput(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      // decimalScale={2}
      // fixedDecimalScale
      // NOTE: You can use the InputAdornment or the prefix, not both...
      // prefix="$"
      {...other}
    />
  );
}

const NumberInputProps = {
  inputComponent: NumberInput,
  // startAdornment: <InputAdornment position="start">$</InputAdornment>,
};

/**
 * A Material-UI `TextField` with USD formatting bound to a `formik` field.
 *
 * @typedef {import("@material-ui/core").TextFieldProps} TextFieldProps
 * @typedef {object} NumberFieldProps
 * @property {Record<string,string>} [classFrom] Object to get `className`
 * from, for the given field `name`.
 * @property {number} [decimals] Number of decimal places.
 * @property {import("react-number-format").NumberFormatProps} [inputProps]
 *
 * @param {NumberFieldProps & TextFieldProps} props
 */
export function NumberField(props) {
  const {
    InputProps = NumberInputProps,
    decimals,
    inputProps: numberFormatInputProps,
    ...rest
  } = props;
  /** @type {import("react-number-format").NumberFormatProps} */
  const inputProps = React.useMemo(
    () =>
      decimals
        ? {
            decimalScale: decimals,
            fixedDecimalScale: true,
            inputMode: "numeric",
            ...numberFormatInputProps,
          }
        : {
            inputMode: "numeric",
            ...numberFormatInputProps,
          },
    [decimals, numberFormatInputProps],
  );
  return <Field {...rest} InputProps={InputProps} inputProps={inputProps} />;
}
