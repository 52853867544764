import React from "react";

export const OfferIcon = React.memo(
  /**
   * @param {React.SVGProps<SVGSVGElement>} param0
   */
  function OfferIcon({
    height = "105px",
    width = "89px",
    viewBox = "0 0 89 105",
    fill = "#4E50FF",
    ...props
  }) {
    return (
      <svg width={width} height={height} viewBox={viewBox} {...props}>
        <title>Get your offer</title>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-280.000000, -1381.000000)">
            <g transform="translate(0.000000, 1256.000000)">
              <g transform="translate(280.000000, 125.000000)">
                <rect
                  fill="#B5C1FF"
                  x="0"
                  y="0"
                  width="80"
                  height="80"
                  rx="3.55555556"
                ></rect>
                <path
                  d="M18.6666667,16 L75.7017526,16 L75.7017526,16 L88,26.5188517 L88,101.333333 C88,102.806093 86.8060927,104 85.3333333,104 L18.6666667,104 C17.1939073,104 16,102.806093 16,101.333333 L16,18.6666667 C16,17.1939073 17.1939073,16 18.6666667,16 Z"
                  stroke="#192740"
                ></path>
                <path
                  d="M22.6666667,20 L77,20 C80.8659932,20 84,23.1340068 84,27 L84,97.3333333 C84,98.8060927 82.8060927,100 81.3333333,100 L22.6666667,100 C21.1939073,100 20,98.8060927 20,97.3333333 L20,22.6666667 C20,21.1939073 21.1939073,20 22.6666667,20 Z"
                  fill="#FFFFFF"
                ></path>
                <path
                  d="M58.0155039,32 L29.9844961,32 C28.8884892,32 28,31.1045695 28,30 C28,28.8954305 28.8884892,28 29.9844961,28 L58.0155039,28 C59.1115108,28 60,28.8954305 60,30 C60,31.1045695 59.1115108,32 58.0155039,32 Z"
                  fill="#DADFF6"
                  fillRule="nonzero"
                ></path>
                <path
                  d="M58.0155039,56 L29.9844961,56 C28.8884892,56 28,55.1045695 28,54 C28,52.8954305 28.8884892,52 29.9844961,52 L58.0155039,52 C59.1115108,52 60,52.8954305 60,54 C60,55.1045695 59.1115108,56 58.0155039,56 Z"
                  fill="#DADFF6"
                  fillRule="nonzero"
                ></path>
                <path
                  d="M43.0077519,64 L28.9922481,64 C28.4442446,64 28,63.1045695 28,62 C28,60.8954305 28.4442446,60 28.9922481,60 L43.0077519,60 C43.5557554,60 44,60.8954305 44,62 C44,63.1045695 43.5557554,64 43.0077519,64 Z"
                  fill="#DADFF6"
                  fillRule="nonzero"
                ></path>
                <path
                  d="M73.0232558,40 L30.9767442,40 C29.3327338,40 28,39.1045695 28,38 C28,36.8954305 29.3327338,36 30.9767442,36 L73.0232558,36 C74.6672662,36 76,36.8954305 76,38 C76,39.1045695 74.6672662,40 73.0232558,40 Z"
                  fill="#DADFF6"
                  fillRule="nonzero"
                ></path>
                <path
                  d="M73.0232558,48 L30.9767442,48 C29.3327338,48 28,47.1045695 28,46 C28,44.8954305 29.3327338,44 30.9767442,44 L73.0232558,44 C74.6672662,44 76,44.8954305 76,46 C76,47.1045695 74.6672662,48 73.0232558,48 Z"
                  fill="#DADFF6"
                  fillRule="nonzero"
                ></path>
                <rect
                  fill={fill}
                  x="28"
                  y="72"
                  width="20"
                  height="20"
                  rx="2.66666667"
                ></rect>
                <path
                  d="M39.1954914,81.0002821 L39.1954914,77.4451773 C39.943484,77.573459 40.6487842,77.8855225 41.2517641,78.3541489 C41.3719728,78.4379617 41.5130205,78.4834306 41.6590475,78.4854306 C42.1269117,78.4854306 42.5083768,78.1026481 42.5133253,77.6269591 C42.5143089,77.4229585 42.4358398,77.2270204 42.294792,77.0815825 C41.4236093,76.3230801 40.3309119,75.8756725 39.1855637,75.809016 L39.1855637,74.617231 C39.1855637,74.276855 38.9143795,74.0011354 38.5796024,74.0011354 C38.572656,74.0000668 38.5656789,74.0000668 38.5587326,74.0000668 C38.219007,73.9950729 37.9388786,74.2708237 37.9338994,74.617231 L37.9338994,75.7686096 C35.6988351,75.9302039 34.1789988,77.3340832 34.1789988,79.2025265 C34.1789988,81.4951587 36.0961908,82.1314731 37.9338994,82.6364435 L37.9338994,86.6763623 C36.9643613,86.5440494 36.0504863,86.1350168 35.3014794,85.4947023 C35.1604317,85.380577 34.9855743,85.3169518 34.8047848,85.3129205 C34.3468484,85.3452331 33.9942136,85.7350781 34.0000719,86.2017045 C33.9991621,86.4057052 34.0776313,86.6016433 34.218679,86.7470812 C35.2448329,87.6863654 36.5640243,88.2267108 37.9437964,88.2721485 L37.9437964,89.3831207 C37.9437964,89.3901832 37.9447799,89.397277 37.9447799,89.4043395 C37.9606705,89.7497468 38.2497431,90.0163726 38.5894687,89.9992163 C38.9242457,89.9992163 39.1954299,89.7234967 39.1954299,89.3831207 L39.1954299,88.2519609 C41.9073026,88.0701791 43,86.3936114 43,84.6160434 C43,82.2324735 41.0332307,81.5052837 39.1954914,81.0002821 Z M37.9438579,80.6770936 C36.8610882,80.3539051 36.0167381,80.0206228 36.0167381,79.0813387 C36.0167381,78.1420545 36.7816355,77.4653649 37.9438579,77.3744896 L37.9438579,80.6770936 L37.9438579,80.6770936 Z M39.1954914,86.6965498 L39.1954914,83.0202572 C40.3180028,83.3434457 41.1921361,83.7777283 41.1822084,84.8382003 C41.1822084,85.6057964 40.6656583,86.514768 39.1954914,86.6965498 Z"
                  fill="#FFFFFF"
                  fillRule="nonzero"
                ></path>
                <path
                  d="M87.4286877,26.6666667 L79.9686673,26.6666667 C77.3757684,26.6638495 75.2745364,24.6358563 75.2716175,22.1333333 L75.2716175,16.2666667 C75.2716175,16.1193907 75.3953199,16 75.5479145,16 C75.7005092,16 75.8242116,16.1193907 75.8242116,16.2666667 L75.8242116,22.1333333 C75.8268001,24.3414367 77.6808207,26.1308351 79.9686673,26.1333333 L87.4286877,26.1333333 C87.5812823,26.1333333 87.7049847,26.2527241 87.7049847,26.4 C87.7049847,26.5472759 87.5812823,26.6666667 87.4286877,26.6666667 L87.4286877,26.6666667 Z"
                  stroke="#192740"
                  strokeWidth="0.75"
                  fill="#192740"
                  fillRule="nonzero"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  },
);
