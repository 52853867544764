import React from "react";
import {
  //
  Typography,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles(
  theme => ({
    root: {
      //
      [theme.breakpoints.down("sm")]: {
        display: "flex",
        flexDirection: "column",
      },
      "& .MuiFormControl-root": {
        paddingRight: 10,
        paddingBottom: 10,
        [theme.breakpoints.down("sm")]: {
          paddingRight: 0,
          width: "100%",
        },
      },
      paddingBottom: 10,
    },
    title: {
      fontSize: 16,
      paddingBottom: 10,
    },
  }),
  {
    classNamePrefix: "InputGroup",
  },
);

export const InputGroup = React.memo(
  /**
   *
   */
  function InputGroup({ children, style, title }) {
    const classes = useStyles();
    return (
      <div className={classes.root} style={style}>
        {title && (
          <Typography variant="h6" className={classes.title}>
            {title}
          </Typography>
        )}
        {children}
      </div>
    );
  },
);
