import React from "react";
import { FileUploader } from "../../lib";

import { AppThunk } from "../types";
import {
  sell,
  // SellState,
  // SellWizardState
} from "./state";
import { authClient } from "./client";
import { SellWizardSectionKeys } from "./wizard";

export { authClient };

const { actions } = sell;

const defaultDealer = process.env.REACT_APP_DEFAULT_DEALER_ID ?? "1001";

interface AcceptedOffer {
  appraisalUid: string;
  offerUid: string;
  amount: number;
  dealer: {
    logoUrl: string;
  };
  status: "accepted" | "replaced";
}

export const dealerSettingIds = {
  /** True if the Affiliates feature-set is enabled. */
  affiliatesEnabled: 40109,
  /** True if the Affiliate field is required when submitting appraisals. */
  appraisalAffiliateRequired: 40110,
  /** True to allow alternate inspector. */
  allowAlternateInspector: 40116,
};

export const sellActions = {
  ...actions,

  acceptOffer(
    appraisalUid: string,
    offerUid: string,
  ): AppThunk<Promise<AcceptedOffer>> {
    return async dispatch => {
      const { data } = await authClient.post<AcceptedOffer>(
        `${appraisalUid}/offer/${offerUid}/accept`,
        {
          accepted: true,
        },
      );
      return data;
    };
  },

  createDocument(
    dealer: string | number = defaultDealer,
    vin: string,
    typeName: string,
    file: File,
  ): AppThunk<Promise<CreateDocumentData>> {
    return async dispatch => {
      const dealerId = Number(dealer);
      const { data } = await authClient.post<CreateDocumentData>(`documents`, {
        dealerId,
        vin,
        name: file.name,
        size: file.size,
        type: file.type,
        typeName,
      });
      return data;
    };
  },
  getAffiliates(dealer = defaultDealer) {
    return (): AppThunk<Promise<DealerAffiliate[]>> => {
      return async dispatch => {
        const { data } = await authClient.get<DealerAffiliate[]>(
          `dealer_affiliates?dealer=${dealer}`,
        );
        return data;
      };
    };
  },
  getBanks(dealer = defaultDealer) {
    return (): AppThunk<Promise<DealerBank[]>> => {
      return async dispatch => {
        const { data } = await authClient.get<DealerBank[]>(
          `dealer_banks?dealer=${dealer}`,
        );
        return data;
      };
    };
  },
  getDealer(dealerId: string, cid?: string): AppThunk<Promise<DealerBank[]>> {
    return async (dispatch, getState) => {
      if (!dealerId) {
        return undefined;
      }
      const dealer = getState().sell.dealer;
      if (dealer.name) {
        return dealer; // Already got it.
      }
      const { data } = await authClient.get(
        `by/${dealerId}${cid ? "?cid=" + cid : ""}`,
      );
      if (data.ds) {
        const ds = JSON.parse(data.ds);
        for (const key in ds) {
          const val = ds[key];
          if (val === "true") {
            ds[key] = true;
          } else if (val === "false") {
            ds[key] = false;
          }
        }
        data.ds = ds;
      }
      data.camp = data.camp ? JSON.parse(data.camp) || {} : {};
      dispatch(actions.saveDealer(data));
      return data;
    };
  },
  getVinDetails(
    dealer: string | number = defaultDealer,
    vin: string,
  ): AppThunk<Promise<VinDetails>> {
    return async (dispatch, getState) => {
      const dealerId = Number(dealer);
      const { data } = await authClient.get<VinDetails>(
        `by/${dealerId}/vin/${vin}`,
      );
      dispatch(actions.setVinDetails(data));
      return data;
    };
  },
  getVinFromPlate(
    dealer: string | number = defaultDealer,
    values: VinFromPlateValues,
  ): AppThunk<Promise<VinFromPlateData>> {
    return async (dispatch, getState) => {
      const dealerId = Number(dealer);
      const { data } = await authClient.get<VinFromPlateData>(
        `by/${dealerId}/plate/${values.state}/${values.plate}`,
      );
      return data;
    };
  },
  saveWizardForm(
    dealer: string | number = defaultDealer,
    vin: string,
    form: SellWizardSectionKeys,
    data: any,
  ): AppThunk {
    return async (dispatch, getState) => {
      // const dealerId = Number(dealer);
      // CONSIDER: Save on the server...
      dispatch(
        actions.savedWizardForm({
          vin,
          name: form,
          data,
        }),
      );
    };
  },
  submitWizard(values: {
    vin: string;
    appraisalTypeId: number;
    query: SellWizardUrlQuery;
  }): AppThunk {
    return async (dispatch, getState) => {
      const {
        vin,
        appraisalTypeId,
        query: { cid, dealer = defaultDealer, duid, sr },
      } = values;
      const dealerId = Number(dealer);
      const wizardState = getState().sell;
      if (!wizardState) {
        throw new Error(`Invalid wizardState for "${vin}"`);
      }
      // TODO: Use the built-in JS URL constructor here instead...
      let url = `by/${dealerId}`;
      const qp: string[] = [];
      if (duid) {
        qp.push(`duid=${duid}`);
      }
      if (cid) {
        qp.push(`cid=${cid}`);
      }
      if (sr === "1") {
        qp.push(`sr=1`);
      }
      if (qp.length > 0) {
        url += "?" + qp.join("&");
      }
      const { data } = await authClient.post(url, {
        appraisalTypeId,
        dealerId,
        vin,
        forms: wizardState.forms,
      });
      dispatch(
        actions.submittedWizard({
          vin,
        }),
      );
      return data;
    };
  },
  uploadDocument(
    dealer: string | number = defaultDealer,
    vin: string,
    typeName: string,
    file: File,
  ): AppThunk<Promise<CreateDocumentData>> {
    return async dispatch => {
      const doc = await dispatch(
        sellActions.createDocument(dealer, vin, typeName, file),
      );
      // const res =
      await FileUploader.upload(file, doc.uploadUrl);
      // console.log("FILEUPLOAD", res);
      return doc;
    };
  },
};

interface VinDetails {
  vin: string;
  vehicles: VehicleFromVin[];
}

interface VinFromPlateData {
  plate: string;
  state: string;
  vin: string;
}

type VinFromPlateValues = {
  plate: string;
  state: string;
};

export function usePrefill(uid: string) {
  const stored = React.useMemo(() => {
    if (!uid) {
      return;
    }
    const json = window.sessionStorage.getItem(`prefill_${uid}`);
    // console.log("LOAD", json);
    if (json) {
      return JSON.parse(json);
    }
    return undefined;
  }, [uid]);
  const [prefill, setPrefill] = React.useState<any>(stored ?? {});

  React.useEffect(() => {
    if (!uid) {
      return;
    }
    // console.log("STORED?", stored);
    if (stored) {
      setPrefill(stored);
      return;
    }
    const state = { mounted: true };
    authClient
      .get(`/prefill/${uid}`)
      .then(res => {
        if (!state.mounted) {
          return;
        }
        const { data } = res;
        setPrefill(data);
        if (data && !stored) {
          window.sessionStorage.setItem(`prefill_${uid}`, JSON.stringify(data));
        }
      })
      .catch(err => {
        console.log(`Getting prefill ${uid}`, "" + err);
      });
    return () => {
      state.mounted = false;
    };
  }, [uid, stored]);

  return prefill;
}
