import { sell, SellState } from "./sell/state";
import { ui, UIState } from "./ui/state";

import { AppStateInfo } from "./types";

// Export Actions, Selectors, Types
export * from "./sell/actions";
export * from "./sell/selectors";
export * from "./sell/wizard";

export * from "./ui/actions";
export * from "./ui/selectors";

export interface AppState {
  sell: SellState;
  ui: UIState;
}

const states: AppStateInfo[] = [
  // Export States
  sell,
  ui,
];
export default states;
