import React from "react";
import { KeyboardDatePicker } from "@material-ui/pickers";
// import { makeStyles } from "@material-ui/core";
// Local
import { useField } from "../../lib";

// const useStyles = makeStyles(
//   theme => ({
//     root: {
//       // margin: 0,
//     },
//   }),
//   {
//     classNamePrefix: "DateField",
//   },
// );

export const DateField = React.memo(
  React.forwardRef(
    /**
     * @typedef {import("@material-ui/pickers").DatePickerProps} DatePickerProps
     * @typedef {object} DateFieldProps
     * @property {Record<string,string>} [classFrom] Object to get `className`
     * from, for the given field `name`.
     *
     * @param {DateFieldProps & DatePickerProps} props
     */
    function DateField(props, ref) {
      // const classes = useStyles();
      const { classFrom, className, name, value: _value, ...other } = props;
      const [fld, meta, helpers] = useField(name);

      const onChangeValue = React.useCallback(
        value => {
          helpers.setValue(value);
        },
        [helpers],
      );

      return (
        <KeyboardDatePicker
          // margin="normal"
          id={name}
          name={name}
          value={fld.value}
          className={className || classFrom?.[name]}
          format="MM/dd/yyyy"
          placeholder="__/__/____"
          onChange={onChangeValue}
          // inputVariant="outlined"
          variant="inline"
          autoOk={true}
          // Setting onBlur makes formik validate the field when the user shows
          // the popup.
          // onBlur={fld.onBlur}
          error={meta.touched && Boolean(meta.error)}
          helperText={meta.touched && meta.error}
          KeyboardButtonProps={{
            "aria-label": "change date",
            // tabIndex: -1, // To disable tabbing to the popup button...
          }}
          {...other}
        />
      );
    },
  ),
);
