import React from "react";
import {
  //
  AppBar,
  // Box,
  Button,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Toolbar,
  // Typography,
  // makeStyles,
} from "@material-ui/core";
// Icons
import MenuIcon from "@material-ui/icons/Menu";
// Local
import { SiteLogo } from "../../components";
import { useStyles } from "./MainToolbar.styles.js";

export const MainToolbar = React.memo(
  /**
   *
   */
  function MainToolbar(/* { toggleMainMenu } */) {
    const classes = useStyles();
    const [menuAnchor, setMenuAnchor] = React.useState(null);
    const onClickMenu = React.useCallback(
      /** @param {React.SyntheticEvent<HTMLElement>} e */
      e => {
        setMenuAnchor(e.currentTarget);
      },
      [],
    );
    const onCloseMenu = React.useCallback(() => {
      setMenuAnchor(null);
    }, []);

    const onClickItem = React.useCallback(
      /** @param {React.SyntheticEvent<HTMLElement>} e */
      e => {
        const navId = e.currentTarget.dataset.id;
        setMenuAnchor(null);
        // Navigate after a brief timeout, to scroll without menu overlay...
        requestAnimationFrame(() => {
          if (navId === "dealer") {
            const win = window.open("https://turnyn.com", "_blank");
            if (win && win.focus) win.focus();
            return;
          }
          const navEl = document.getElementById(navId);
          if (navEl) {
            navEl.scrollIntoView({
              behavior: "smooth",
            });
          }
        });
      },
      [],
    );

    return (
      <AppBar className={classes.root} elevation={0} position="static">
        <Toolbar className={classes.tools} disableGutters>
          <div className={classes.brand}>
            <Link href={`/${window.location.search}`}>
              <SiteLogo height="32px" />
            </Link>
          </div>
          <div className={classes.buttons}>
            <Button onClick={onClickItem} data-id="how-it-works">
              How it works
            </Button>
            <Button onClick={onClickItem} data-id="faq">
              Faq
            </Button>
            <Button onClick={onClickItem} data-id="about">
              About us
            </Button>
            <Button
              onClick={onClickItem}
              data-id="dealer"
              variant="contained"
              disableElevation
            >
              I'm a dealer
            </Button>
          </div>
          <IconButton
            edge="end"
            className={classes.toggle}
            color="inherit"
            aria-label="menu"
            onClick={onClickMenu}
            aria-controls="app-menu"
            aria-haspopup="true"
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="app-menu"
            anchorEl={menuAnchor}
            keepMounted
            open={Boolean(menuAnchor)}
            onClose={onCloseMenu}
          >
            <MenuItem data-id="how-it-works" onClick={onClickItem}>
              How it works
            </MenuItem>
            <MenuItem data-id="faq" onClick={onClickItem}>
              Faq
            </MenuItem>
            <MenuItem data-id="about" onClick={onClickItem}>
              About us
            </MenuItem>
            <MenuItem
              data-id="dealer"
              onClick={onClickItem}
              variant="contained"
            >
              I'm a dealer
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
    );
  },
);
