import axios from "axios";

export class FileUploader {
  /**
   * @param {File} file
   * @param {any} [headers]
   */
  static async upload(file, url, headers) {
    const config = {
      headers: {
        "Content-Type": file.type,
        ...headers,
      },
      // onUploadProgress: handleFileProgress(onUploadProgress),
      // timeout,
    };
    const response = await axios.put(url, file, config);
    return response;
  }
}
