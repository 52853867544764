import React from "react";
import {
  //
  Box,
  Link,
  // Typography,
  makeStyles,
} from "@material-ui/core";
// Location
import { formatAmountUSD, useLocation, useOnMount } from "../../lib";
import {
  sellActions,
  sellSelectors,
  useAction,
  useSelector,
} from "../../state";
import { SiteLogo } from "../../components";
import { telem } from "../../telem";
import { CheckEmailIcon } from "./icons/CheckEmailIcon";

const useStyles = makeStyles(
  theme => ({
    root: {
      display: "flex",
      flexDirection: "column",
      height: "100vh",
      padding: "32px 120px",
      [theme.breakpoints.down("sm")]: {
        padding: "16px 60px",
      },
    },
  }),
  {
    classNamePrefix: "AcceptOfferPage",
  },
);

export const AcceptOfferPage = React.memo(
  /**
   *
   */
  function AcceptOfferPage({ page }) {
    const classes = useStyles();
    const location = useLocation();
    const {
      params: { appraisalUid, offerUid },
      query: { dealer: dealerId },
    } = location;

    const getDealer = useAction(sellActions.getDealer);
    const dealer = useSelector(sellSelectors.dealer);

    const acceptOffer = useAction(sellActions.acceptOffer);
    /** @type {["accepted" | "loading" | "replaced"]} */
    const [status, setStatus] = React.useState("loading");
    const [dealerLogoUrl, setDealerLogoUrl] = React.useState("");
    const [amount, setAmount] = React.useState(0);
    const [loading, setLoading] = React.useState(true);
    const [error, setError] = React.useState(false);

    useOnMount(() => {
      getDealer(dealerId);
      telem.logVisit(location, {
        appraisalUid,
        appraisalOfferUid: offerUid,
      });
      acceptOffer(appraisalUid, offerUid)
        .then(data => {
          setStatus(data.status);
          setDealerLogoUrl(data.dealer?.logoUrl);
          setAmount(data.amount);
          setLoading(false);
        })
        .catch(reason => {
          setError(true);
          setLoading(false);
        });
    });

    return (
      <div className={classes.root}>
        <Box>
          <Link href={`/${window.location.search}`}>
            <SiteLogo height="32px" />
          </Link>
        </Box>
        <Box display="flex" justifyContent="center" flex="1">
          <Box
            alignItems="center"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            maxWidth="440px"
          >
            <Box height="192px" width="232px">
              <Box
                bgcolor="#B5C1FF"
                borderRadius="8px"
                height="160px"
                position="relative"
                top={0}
                left={0}
                zIndex={0}
                width="160px"
              />
              <Box position="relative" left={30} top={-150}>
                <CheckEmailIcon />
              </Box>
            </Box>
            <Box
              color="#141932"
              fontSize="32px"
              fontWeight="600"
              lineHeight="48px"
              paddingTop="40px"
            >
              {loading
                ? "Loading..."
                : error
                ? "Sorry, there was an error!"
                : status === "replaced"
                ? "Offer expired."
                : "Thank you!"}
            </Box>
            <Box
              color="#141932"
              fontSize="20px"
              lineHeight="30px"
              paddingTop="8px"
              textAlign="center"
            >
              {!loading &&
                !error &&
                (status === "replaced" ? (
                  <>
                    This offer has expired. Please check your email for a more
                    up to date offer.
                  </>
                ) : (
                  <>
                    You’ve accepted our offer of{" "}
                    {formatAmountUSD(amount, {
                      decimalIfNotWhole: true,
                    })}
                    . We’ll be in touch with you soon.
                  </>
                ))}
            </Box>
            <Box boxShadow={24} marginTop="24px" padding="12px">
              <img
                src={loading ? dealer?.logoUrl : dealerLogoUrl}
                height={48}
                alt="Dealer"
              />
            </Box>
          </Box>
        </Box>
      </div>
    );
  },
);
