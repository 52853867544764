import React from "react";

export const GetPaidIcon = React.memo(
  /**
   * @param {React.SVGProps<SVGSVGElement>} param0
   */
  function GetPaidIcon({
    height = "110px",
    width = "121px",
    viewBox = "0 0 121 110",
    fill = "#4E50FF",
    ...props
  }) {
    return (
      <svg width={width} height={height} viewBox={viewBox} {...props}>
        <title>Get paid</title>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-280.000000, -1722.000000)">
            <g transform="translate(0.000000, 1256.000000)">
              <g transform="translate(280.000000, 466.000000)">
                <g>
                  <g>
                    <rect
                      fill="#B5C1FF"
                      x="0"
                      y="0"
                      width="80"
                      height="80"
                      rx="3.55555556"
                    ></rect>
                    <rect
                      stroke="#192740"
                      x="16"
                      y="32"
                      width="104"
                      height="56"
                      rx="2.66666667"
                    ></rect>
                    <rect
                      fill="#FFFFFF"
                      x="20"
                      y="36"
                      width="96"
                      height="48"
                      rx="2.66666667"
                    ></rect>
                    <rect
                      fill="#DADFF6"
                      x="52"
                      y="48"
                      width="56"
                      height="4"
                      rx="2"
                    ></rect>
                    <rect
                      fill="#DADFF6"
                      x="52"
                      y="56"
                      width="56"
                      height="4"
                      rx="2"
                    ></rect>
                    <rect
                      fill="#DADFF6"
                      x="52"
                      y="64"
                      width="40"
                      height="4"
                      rx="2"
                    ></rect>
                    <path
                      d="M21,36 L44,36 L44,36 L44,84 L21,84 C20.4477153,84 20,83.5522847 20,83 L20,37 C20,36.4477153 20.4477153,36 21,36 Z"
                      fill={fill}
                    ></path>
                    <path
                      d="M33.1954914,59.0002821 L33.1954914,55.4451773 C33.943484,55.573459 34.6487842,55.8855225 35.2517641,56.3541489 C35.3719728,56.4379617 35.5130205,56.4834306 35.6590475,56.4854306 C36.1269117,56.4854306 36.5083768,56.1026481 36.5133253,55.6269591 C36.5143089,55.4229585 36.4358398,55.2270204 36.294792,55.0815825 C35.4236093,54.3230801 34.3309119,53.8756725 33.1855637,53.809016 L33.1855637,52.617231 C33.1855637,52.276855 32.9143795,52.0011354 32.5796024,52.0011354 C32.572656,52.0000668 32.5656789,52.0000668 32.5587326,52.0000668 C32.219007,51.9950729 31.9388786,52.2708237 31.9338994,52.617231 L31.9338994,53.7686096 C29.6988351,53.9302039 28.1789988,55.3340832 28.1789988,57.2025265 C28.1789988,59.4951587 30.0961908,60.1314731 31.9338994,60.6364435 L31.9338994,64.6763623 C30.9643613,64.5440494 30.0504863,64.1350168 29.3014794,63.4947023 C29.1604317,63.380577 28.9855743,63.3169518 28.8047848,63.3129205 C28.3468484,63.3452331 27.9942136,63.7350781 28.0000719,64.2017045 C27.9991621,64.4057052 28.0776313,64.6016433 28.218679,64.7470812 C29.2448329,65.6863654 30.5640243,66.2267108 31.9437964,66.2721485 L31.9437964,67.3831207 C31.9437964,67.3901832 31.9447799,67.397277 31.9447799,67.4043395 C31.9606705,67.7497468 32.2497431,68.0163726 32.5894687,67.9992163 C32.9242457,67.9992163 33.1954299,67.7234967 33.1954299,67.3831207 L33.1954299,66.2519609 C35.9073026,66.0701791 37,64.3936114 37,62.6160434 C37,60.2324735 35.0332307,59.5052837 33.1954914,59.0002821 Z M31.9438579,58.6770936 C30.8610882,58.3539051 30.0167381,58.0206228 30.0167381,57.0813387 C30.0167381,56.1420545 30.7816355,55.4653649 31.9438579,55.3744896 L31.9438579,58.6770936 L31.9438579,58.6770936 Z M33.1954914,64.6965498 L33.1954914,61.0202572 C34.3180028,61.3434457 35.1921361,61.7777283 35.1822084,62.8382003 C35.1822084,63.6057964 34.6656583,64.514768 33.1954914,64.6965498 Z"
                      fill="#FFFFFF"
                      fillRule="nonzero"
                    ></path>
                  </g>
                  <g transform="translate(31.280773, 90.666667)"></g>
                </g>
                <g
                  transform="translate(63.909861, 18.000000)"
                  fillRule="nonzero"
                >
                  <path
                    d="M0.399450749,55.6819288 C0.885523164,55.5601622 4.28803008,54.4642622 7.44750078,54.8295622 C7.44750078,53.1248289 8.90571803,50.6894956 10.1208991,49.4718289 L13.7664422,45.818829 L11.9436706,43.992329 L10.1208991,42.165829 L6.47535595,45.818829 C5.26017491,47.0364956 2.82981283,48.4976956 1.12855937,48.4976956 C1.61463179,51.7853956 0.520968852,55.1948622 0.399450749,55.6819288 Z"
                    fill="#9BACFF"
                  ></path>
                  <path
                    d="M15.5892137,46.7929623 C18.6271663,44.357629 25.5536983,37.7822291 29.0777233,34.3727625 C39.1637259,24.2661293 57.999032,2.10459627 55.9332243,0.156329628 C53.8674165,-1.91370368 31.7511216,17.0818961 21.786637,27.0667626 C18.3841301,30.4762292 11.8221525,37.4169291 9.39179044,40.5828624 L15.5892137,46.7929623 Z"
                    fill="#9BACFF"
                  ></path>
                  <polygon
                    fill="#F9FAFC"
                    points="6.35383784 43.8705623 12.1867068 49.7153623 15.5892137 46.7929623 9.27027234 40.5828624"
                  ></polygon>
                  <g transform="translate(0.000000, 51.176562)" fill="#EFF1FB">
                    <ellipse
                      cx="4.16651197"
                      cy="0.73059999"
                      rx="1"
                      ry="1"
                    ></ellipse>
                    <polygon
                      transform="translate(2.245935, 2.663713) rotate(45.000000) translate(-2.245935, -2.663713) "
                      points="2.12155917 -0.012518275 2.3648416 -0.0122697164 2.37030989 5.33994387 2.12702746 5.33969531"
                    ></polygon>
                    <ellipse
                      cx="0.642486956"
                      cy="4.38359994"
                      rx="1"
                      ry="1"
                    ></ellipse>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  },
);
