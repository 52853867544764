import React from "react";
import { Link } from "@material-ui/core";
// Local
import { RouterLink } from "../../lib";

/** See https://stackoverflow.com/a/52061351/16387 */
const disabledStyle = {
  pointerEvents: "none",
};

export const PageLink = React.memo(
  React.forwardRef(
    /**
     * @typedef {import("@material-ui/core").LinkProps} LinkProps
     * @typedef {import("react-router-dom").LinkProps} RouterLinkProps
     * @typedef {object} PageLinkProps
     * @property {boolean} [disabled]
     * @property {PageDefinition} [page]
     *
     * @param {LinkProps & RouterLinkProps & PageLinkProps} param0
     */
    function PageLink({ children, disabled, page, style, to, ...rest }, ref) {
      if (page) {
        to = page.path;
      }
      if (disabled) {
        style = {
          ...style,
          ...disabledStyle,
        };
      }
      return (
        <Link
          ref={ref}
          component={RouterLink}
          disabled={disabled}
          style={style}
          to={to}
          {...rest}
        >
          {children}
        </Link>
      );
    },
  ),
);
