import React from "react";
import { Router, Switch, Route } from "react-router-dom";
// Local
import {
  // RouteSecurityProvider,
  history,
  renderPageRoutes,
} from "./lib";
// import { authSelectors, useSelector } from "./state";
import {
  // AuthLayout,
  MainLayout,
} from "./layouts";
import { Pages } from "./pages";

export const App = React.memo(function App() {
  // const auth = useSelector(authSelectors.route);
  return (
    // <RouteSecurityProvider auth={auth} check={checkAuth}>
    <Router history={history}>
      <Switch>
        {/* <Route path="/auth">
          <AuthPages />
        </Route> */}
        <Route path="/">
          <MainPages />
        </Route>
      </Switch>
    </Router>
    // </RouteSecurityProvider>
  );
});

// const AuthPages = React.memo(function AuthPages() {
//   return (
//     <AuthLayout>
//       <Switch>
//         {renderPageRoutes(Pages.auth)}
//         {renderPageRoutes(Pages.main.notFound)}
//       </Switch>
//     </AuthLayout>
//   );
// });

const MainPages = React.memo(function MainPages() {
  // NOTE: `Pages.main` should be last since it renders the notFound page route.
  return (
    <MainLayout>
      <Switch>
        {renderPageRoutes(Pages.offer)}
        {renderPageRoutes(Pages.sell)}
        {renderPageRoutes(Pages.main)}
      </Switch>
    </MainLayout>
  );
});

// /**
//  * Function that should return true to allow the guarded route to render or
//  * false to redirect using the default redirect which was passed to
//  * `RouteSecurityProvider` (if any) or a `LocationDescriptor` object to redirect
//  * to a specific `pathname` and `search` query.
//  *
//  * @param {RouteCheckProps<ReturnType<typeof authSelectors.route>>} props
//  * @returns {ReturnType<RouteCheckProps["check"]>}
//  */
// function checkAuth(props) {
//   const { auth, location, roles: requiredRoles } = props;
//   let allowed = false;
//   // console.log("CHECKING", requiredRoles, auth.roles);
//   if (requiredRoles) {
//     const authRoles = auth.roles;
//     allowed = requiredRoles.some(role => authRoles.includes(role));
//   } else {
//     allowed = auth.isLoggedIn;
//   }
//   // console.log("ALLOWED", allowed);
//   if (!allowed) {
//     // Return a LocationDescriptor object to redirect to login, then back to
//     // the current location after login...
//     return {
//       pathname: Pages.auth.login.path,
//       search: "?after=" + location.pathname + location.search,
//     };
//   }
//   // Return true to allow the route to render.
//   return true;
// }
