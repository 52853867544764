import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// Local
import { SellWizardForms, SellWizardSectionKeys } from "./wizard";

const initialState: SellState = {
  dealer: {
    name: "",
    publicName: "",
    logoUrl: "",
    ds: {},
    camp: {},
  },
  vin: "",
  forms: {},
  vehicles: [],
};

export const sell = {
  ...createSlice({
    name: "sell",
    initialState,
    reducers: {
      saveDealer(state, { payload }: PayloadAction<any>) {
        state.dealer = payload;
      },
      savedWizardForm(
        state,
        {
          payload,
        }: PayloadAction<{
          vin: string;
          name: SellWizardSectionKeys;
          data: any;
        }>,
      ) {
        const { vin, name, data } = payload;
        state.vin = vin;
        state.forms[name] = data;
      },
      setVinDetails(
        state,
        { payload }: PayloadAction<Partial<SellWizardState>>,
      ) {
        return {
          ...initialState,
          dealer: state.dealer,
          ...payload,
        };
      },
      submittedWizard(state, { payload }: PayloadAction<{ vin: string }>) {
        return {
          ...initialState,
          dealer: state.dealer,
        };
      },
    },
  }),
  persist: false,
};

export interface SellState {
  dealer: {
    name: string;
    publicName: string;
    logoUrl: string;
    /** Dealer settings by id. */
    ds: Record<string, any>;
    /** Campaign info. */
    camp: Record<"type" | string, any>;
  };
  vin: string;
  forms: SellWizardForms;
  vehicles: VehicleFromVin[];
}
