import { makeStyles } from "@material-ui/core";

/** If appbar/toolbar is fixed, use this to adjust top paddings. */
export const mainToolbarHeight = 48;

export const useStyles = makeStyles(
  theme => ({
    root: {
      backgroundColor: "#ffff",
      zIndex: theme.zIndex.drawer + 1,
    },
    brand: {
      flex: 1,
    },
    buttons: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      color: "#000",
      "& .MuiButton-root:not(:last-child)": {
        marginRight: 40,
      },
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    toggle: {
      color: "#000",
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
  }),
  {
    classNamePrefix: "MainToolbar",
  },
);
