import React from "react";
// import { Prompt } from "react-router-dom";
import { FormikProvider } from "formik";

const DEFAULT_PROMPT = "You have unsaved changes...";

let promptHandlers = [];

function addPromptHandler(handler) {
  removePromptHandlers([handler]);
  window.addEventListener("beforeunload", handler);
}

function removePromptHandlers(replacement = []) {
  promptHandlers.forEach(handler => {
    window.removeEventListener("beforeunload", handler);
  });
  promptHandlers = replacement;
}

export const Form = React.memo(
  /**
   * Form element that implements FormikProvider for a `useFormik` component.
   * See:
   * - https://github.com/formium/formik/discussions/2746
   * - https://github.com/formium/formik/blob/formik%402.2.5/packages/formik/src/Form.tsx
   *
   * @typedef {object} FormProps
   * @property {React.ReactNode} children
   * @property {ReturnType<typeof import("formik").useFormik>} form
   * @property {string | false} promptOnExit
   *
   * @param {FormProps & JSX.IntrinsicElements["form"]} param0
   */
  function Form({
    form: frm,
    children,
    action,
    promptOnExit = DEFAULT_PROMPT,
    ...rest
  }) {
    const promptUser = React.useCallback(
      /** @param {React.SyntheticEvent<HTMLElement>} e */
      (e = {}) => {
        if (e.preventDefault) e.preventDefault();
        return (e.returnValue = promptOnExit);
      },
      [promptOnExit],
    );
    React.useEffect(() => {
      if (!promptOnExit) {
        return;
      }
      if (frm.dirty) {
        addPromptHandler(promptUser);
      } else {
        removePromptHandlers();
      }
      return removePromptHandlers;
    }, [frm.dirty, promptOnExit, promptUser]);
    return (
      <FormikProvider value={frm}>
        <form
          onReset={frm.handleReset}
          onSubmit={frm.handleSubmit}
          // iOS needs an "action" attribute for nice input: https://stackoverflow.com/a/39485162/406725
          // We default the action to "#" in case the preventDefault fails (just updates the URL hash)
          action={action || "#"}
          {...rest}
        >
          {children}
        </form>
      </FormikProvider>
    );
  },
);
