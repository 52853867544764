import { AcceptOfferPage } from "./AcceptOfferPage";

export const OfferPages = {
  accept: {
    anon: true,
    path: "/appraisals/:appraisalUid/offer/:offerUid/accept",
    title: "Offer Accepted",
    view: AcceptOfferPage,
  },
};
